import React, { useEffect, useRef, useState } from "react";
import APIServices from "../../service/APIService";
import { API } from "../../constants/api_url";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import $ from "jquery";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import FileSaver, { saveAs } from "file-saver";
import { InputTextarea } from "primereact/inputtextarea";
import { TabMenu } from "primereact/tabmenu";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { MultiSelect } from "primereact/multiselect";
import { ListBox } from "primereact/listbox";
import { Checkbox } from "primereact/checkbox";
import { DateTime } from "luxon";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { FilterMatchMode, FilterService } from 'primereact/api';
import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { InputSwitch } from "primereact/inputswitch";
import { checkRoleAccessByRoleIds, getLocationData } from "../../components/BGHF/helper";
import { tvssection } from "../../assets/tvs/js/tvssection";
import { ReadMoreComponent } from "../../components/Forms/ReadMoreComponent";
let activeRowData = {}

const TVSAssignDcfToUser = () => {
    const { fymonth } = useSelector((state) => state.user.fyStartMonth);
    const allRoles = useSelector((state) => state.user.allRoles);
    
    const tvsSubAdmin = useSelector((state) => state.user.tvsSubAdmin);
    const tvsSubAdminRoles = useSelector((state) => state.user.tvsSubAdminRoles)
    let tvsSubAdminRole = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
    const navigate = useHistory()
    const sumdtref = useRef(null)
    const [dcfentityass, setDcfEntityAss] = useState([])
    const [dfentityass, setDfEntityAss] = useState([])
    const [srfentityass, setSrfEntityAss] = useState([])
    const [rfresponse, setRFResponse] = useState([])
    const [load, setLoading] = useState(true)
    const [rawrf, setRawRF] = useState([])
    const [rawsrf, setRawSRF] = useState([])
    const [rfentityass, setRFEntityAss] = useState([])
    const [minDate, setMinDate] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const forceUpdate = useForceUpdate()
    const [dcfentityuserass, setDcfEntityUserAss] = useState([])
    const [summarydata, setSummaryData] = useState([])
    const [overallfilter, setOverallFilter] = useState({ ent: { value: null, matchMode: 'in' }, frequency: { value: null, matchMode: 'in' }, formtype: { value: null, matchMode: 'in' }, form: { value: null, matchMode: 'in' } })
    const [dfentityuserass, setDfEntityUserAss] = useState([])
    const [srfentityuserass, setSrfEntityUserAss] = useState([])
    const [frequencyMonths, setFrequencyMonths] = useState([])
    const [dflist, setDfList] = useState([])
    const [stdlist, setStdList] = useState([])
    const [stdlistBk, setStdListBK] = useState([])
    const [selectedloclist, setSelectedLocList] = useState({ tier1: [], tier2: [], tier3: [] })
    const [assigneddcflist, setAssignedDcfList] = useState([])
    const [assignedsrflist, setAssignedSrfList] = useState([])
    const [requiredList, setRequiredList] = useState([])
    const [requiredListBK, setRequiredListBK] = useState([])
    const [requiredList2, setRequiredList2] = useState([])
    const [assFramework, setAssFramework] = useState([])
    const [selectedFramework2, setSelectedFramework2] = useState([])
    const [assFramework2, setAssFramework2] = useState([])
    const [selectedFramework, setSelectedFramework] = useState([])
    const [historydata, setHistoryData] = useState([])
    const [historydialog, setHistoryDialog] = useState(false)
    const [sectionlist, setSectionList] = useState([{ title: 'Sustainability', id: 8 }, { title: 'Health', id: 9 }, { title: 'Safety Central', id: 10 }, { title: 'Safety Operational', id: 11 }, { title: 'Supply Chain', id: 12 }, { title: 'Dealership Sustainability', id: 13 }, { title: 'Environmental', id: 14 }, { title: 'Social', id: 15 }, { title: 'Governance', id: 16 }])

    const [requiredList2BK, setRequiredList2BK] = useState([])
    const [selecteddcfentity, setSelectedDcfEntity] = useState({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], title: null, dcfId: null })
    const [selectedsrfentity, setSelectedSrfEntity] = useState({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], title: null, srfId: null })
    const [selecteddfentity, setSelectedDfEntity] = useState({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], title: null, dfId: null, type: null })
    const [dcfentityuserobj, setDcfEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], standard: null })
    const [allentityuserobj, setAllEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], standard: null })
    const [srfentityuserobj, setSrfEntityUserObj] = useState({ frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [] })
    const [dfentityuserobj, setDfEntityUserObj] = useState({ frequency: 4, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], type: null })
    const [dcfentitydialog, setDcfEntityDialog] = useState(false)
    const [srfentitydialog, setSrfEntityDialog] = useState(false)
    const [dfentitydialog, setDfEntityDialog] = useState(false)
    const [dcfentityuserdialog, setDcfEntityUserDialog] = useState(false)
    const [dfentityuserdialog, setDfEntityUserDialog] = useState(false)
    const [srfentityuserdialog, setSrfEntityUserDialog] = useState(false)
    const [allentityuserdialog, setAllEntityUserDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [dcfentityviewdialog, setDcfEntityViewDialog] = useState(false)
    const [srfentityviewdialog, setSrfEntityViewDialog] = useState(false)
    const [dfenitityviewdialog, setDfEnitityViewDialog] = useState(false)
    const userList_ = useSelector(state => state.userlist.userList)
    const supplierList_ = useSelector(state => state.userlist.supplierList)
    const [assignedsection, setAssignedSection] = useState([])
    const [rawsitelist, setRawSitelist] = useState([])
    const [roleasslist, setRoleAssList] = useState([])

    const [userList, setUserList] = useState([]);
    const [summaryyear, setSummaryYear] = useState(0);
    const [supplierList, setSupplierList] = useState([]);
    const [approverList, setApproverList] = useState([]);
    const [sectionAdminList, setSectionAdminList] = useState([]);
    const [reviewerList, setReviewerList] = useState([]);
    const [yearoptions, setYearOptions] = useState([])
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }]
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);

    const entryStartDate = DateTime.fromISO(admin_data.information.startdate, { zone: 'utc' }).toJSDate()
    const [label1, label2, label3] = useSelector((state) => state.user.tierLabel);
    const [qlyearoption, setQlYearOption] = useState(getFiscalYearsFromStartDate(admin_data.information.startdate, fymonth))

    useEffect(() => {
        let uriString = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }

        let yrOptions = getFiscalYearsFromStartDate(admin_data.information.startdate)
        setYearOptions(yrOptions)
        const promise0 = APIServices.get(API.AssignDCFClient_UP(admin_data.id))
        const curatedIndicators =   Array.from(new Set(Object.entries(tvssection).flatMap(i => (( tvsSubAdminRoles.includes(parseFloat(i[0]))  || allRoles.includes(3) || login_data.role === 'clientadmin')  ? i[1] : []))))
        const promise1 = APIServices.get(API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise2 = APIServices.get(API.DCF)
        const promise3 = APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`)
        const promise4 = APIServices.get(API.DCF_Entity_UP(admin_data.id))
        const promise5 = APIServices.get(API.DCF_Entity_User_UP(admin_data.id))
        const promise6 = APIServices.get(API.UserProfile)
        const promise7 = APIServices.get(API.EF_Std)
        const promise8 = APIServices.get(API.RF)
        const promise9 = APIServices.get(API.QL_Submit_UP(admin_data.id))
        const promise10 = APIServices.get(API.Report_Name_Twos)
        const promise11 = APIServices.get(API.DF_Entity_UP(admin_data.id))
        const promise12 = APIServices.get(API.DF_Entity_User_UP(admin_data.id))
        const promise13 = APIServices.get(API.SRF)
        const promise14 = APIServices.get(API.SRF_Entity_UP(admin_data.id))
        const promise15 = APIServices.get(API.SRF_Entity_User_UP(admin_data.id))
        const promise16 = APIServices.get(API.GetRole_Up(admin_data.id))
        const promise17 = APIServices.get(API.Role_Application(1))



        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10, promise11, promise12, promise13, promise14, promise15, promise16, promise17]).then((values) => {
            let dcf_list = values[2].data, assinged_dcf = [], assinged_srf = [], assigned_dcf_entity = values[4].data, assinged_rf_entity = values[11].data, assigned_srf_entity = values[14].data
            let tier1 = [], tier2 = [], tier3 = [], approver_list = [{ id: admin_data.id, name: 'Enterprise Admin' }], reviewer_list = [{ id: admin_data.id, name: 'Enterprise Admin' }], reporter_list = [{ id: admin_data.id, name: 'Enterprise Admin' }], supplier_list = []
            let df_list = []
            setSectionAdminList(Array.from(new Set(values[16].data.filter(x => x.roles && x.roles.some(y => tvsSubAdminRole.includes(y))).map(i => i.user_id))).map(i => values[6].data.find(x => x.id === i)).filter(x => x).map(i => ({ id: i.id, name: i.information.empname })))
            setRoleAssList(values[16].data)
            setStdListBK(values[7].data)
            setAssignedSection(!tvsSubAdmin ? sectionlist : tvsSubAdminRoles.filter(i => values[17].data.map(x => x.id).includes(i)).map(i => values[17].data.find(x => x.id === i)))
            console.log(Array.from(new Set(values[16].data.filter(x => x.roles && x.roles.some(y => tvsSubAdminRole.includes(y))).map(i => i.user_id))).map(i => values[6].data.find(x => x.id === i)).filter(x => x).map(i => ({ id: i.id, name: i.information.empname })))

            values[6].data.filter(i => (i.information.cid === admin_data.id && i.role === 'clientsupplier')).forEach((item) => {
                if (item.information.role !== undefined && item.information.role.reporter) {
                    supplier_list.push({
                        name: item.information.empname,
                        id: item.id,
                    })
                }
            })
            values[6].data.filter(i => (i.information.cid === admin_data.id && i.role === 'clientuser')).forEach((item) => {
                if (item.information.role !== undefined && item.information.role.reporter) {
                    reporter_list.push({
                        name: item.information.empname,
                        id: item.id,
                    })
                }
                if (item.information.role !== undefined && item.information.role.reviewer) {
                    reviewer_list.push({
                        name: item.information.empname,
                        id: item.id,
                    })
                }
                if (item.information.role !== undefined && item.information.role.approver) {
                    approver_list.push({
                        name: item.information.empname,
                        id: item.id,
                    })
                }

            })
            // DF

            let required_rf = [], optional_rf = []
            setRawRF(values[8].data)
            setRawSRF(values[13].data)
            setRFResponse(values[9].data)
            // DF
            setApproverList(approver_list)
            setReviewerList(reporter_list)
            setUserList(reporter_list)
            setSupplierList(supplier_list)
            setDcfEntityAss(assigned_dcf_entity)
            setSrfEntityAss(assigned_srf_entity)
            setDfEntityAss(assinged_rf_entity)

            let shapedSite = []
            let adminShapedSite = values[3].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            if (login_data.role === 'clientadmin') {
                shapedSite = values[3].data
                    .map((item) => {
                        if (item.locationTwos) {
                            item.locationTwos = item.locationTwos.filter(
                                (locationTwo) =>
                                    locationTwo.locationThrees &&
                                    locationTwo.locationThrees.length > 0
                            );
                        }
                        return item;
                    })
                    .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            } else {
                shapedSite = getLocationData(values[3].data, values[16].data.filter(i => i.user_id === login_data.id), !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles).map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                    .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            }


            for (const dcfentuser of values[5].data) {

                dcfentuser.reporter_ids = dcfentuser.reporter_ids.filter((i) => {
                    return (i === admin_data.id || checkRoleAccessByRoleIds([i], [1], dcfentuser.level, dcfentuser.locationId, values[16].data, adminShapedSite).length !== 0)

                })
                dcfentuser.reviewer_ids = dcfentuser.reviewer_ids.filter((i) => {
                    return (i === admin_data.id || checkRoleAccessByRoleIds([i], [4], dcfentuser.level, dcfentuser.locationId, values[16].data, adminShapedSite).length !== 0)

                })
                dcfentuser.approver_ids = []
                console.log(dcfentuser)
            }
            for (const dcfentuser of values[12].data) {

                dcfentuser.reporter_ids = dcfentuser.reporter_ids.filter((i) => {
                    return (i === admin_data.id || checkRoleAccessByRoleIds([i], [1], dcfentuser.level, dcfentuser.locationId, values[16].data, adminShapedSite).length !== 0)

                })

            }
            for (const dcfentuser of values[15].data) {

                dcfentuser.reporter_ids = dcfentuser.reporter_ids.filter((i) => {
                    return (i === admin_data.id || checkRoleAccessByRoleIds([i], [1], dcfentuser.level, dcfentuser.locationId, values[16].data, adminShapedSite).length !== 0)

                })
                dcfentuser.reviewer_ids = dcfentuser.reviewer_ids.filter((i) => {
                    return (i === admin_data.id || checkRoleAccessByRoleIds([i], [4], dcfentuser.level, dcfentuser.locationId, values[16].data, adminShapedSite).length !== 0)

                })
                dcfentuser.approver_ids = []
            }
            setDcfEntityUserAss(values[5].data)
            setSrfEntityUserAss(values[15].data)
            setDfEntityUserAss(values[12].data)
            const shapedCategory = values[1].data.map(item => {
                if (item.newTopics) {
                    item.newTopics = item.newTopics.filter(topics =>
                        topics.newMetrics && topics.newMetrics.length > 0
                    );
                }
                return item;
            }).filter(item => item.newTopics && item.newTopics.length > 0)
            console.log(shapedCategory)
            shapedSite.forEach((i) => {
                tier1.push({ name: i.name, id: i.id })
                i.locationTwos.forEach((j) => {
                    tier2.push({ name: j.name, id: j.id })
                    j.locationThrees.forEach((k) => {
                        tier3.push({ name: k.name, id: k.id })
                    })
                })

            })



            setSelectedLocList({ tier1, tier2, tier3 })
            console.log(shapedCategory.flatMap(i => i.newTopics.flatMap(j => j.newMetrics)))

            setRawSitelist(adminShapedSite)
            if (values[0].data.length !== 0) {
                // DF
                let topic_ids = values[0].data[0].topic_ids
                if (Array.isArray(values[0].data[0].cf_ids)) {
                    assinged_srf = values[13].data.filter((m) => values[0].data[0].cf_ids.filter(i => values[13].data.map(j => j.id).includes(i)).includes(m.id)).filter(i => (i.tags === null || i.tags.includes(admin_data.id)))


                }
                console.log(tvsSubAdmin, curatedIndicators)
                shapedCategory.forEach((cat) => {
                    if (cat.newTopics) {
                        cat.newTopics.forEach((topic) => {
                            if (topic_ids.includes(topic.id) && (topic.tag === null || parseFloat(topic.tag) === admin_data.id) && topic.newMetrics) {
                                topic.newMetrics.forEach((metric) => {

                                    if ((curatedIndicators.includes(metric.id) ) && Array.isArray(metric.data1) && metric.data1[0] !== undefined && metric.data1[0].type === 1 && values[8].data.map(i => i.id).includes(metric.data1[0].rf) && (parseFloat(metric.tag) === admin_data.id)) {

                                        let rf_index = values[8].data.findIndex(i => i.id === metric.data1[0].rf)
                                        if (rf_index !== -1 && (!(values[8].data[rf_index].tags != null) || (values[8].data[rf_index].tags && !values[8].data[rf_index].tags.length)  || (values[8].data[rf_index].tags && values[8].data[rf_index].tags.includes(admin_data.id)))) {
                                            required_rf.push({ section: findKeyById(tvssection, metric.id), cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric })
                                            df_list.push(values[8].data[rf_index])
                                        }


                                    }
                                })
                            } else if (!topic_ids.includes(topic.id) && topic.newMetrics && (topic.tag === null || parseFloat(topic.tag) === admin_data.id)) {
                                topic.newMetrics.forEach((metric) => {
                                    if ((curatedIndicators.includes(metric.id) || !tvsSubAdmin) && Array.isArray(metric.data1) && metric.data1[0] !== undefined && metric.data1[0].type === 1 && values[8].data.map(i => i.id).includes(metric.data1[0].rf) && (metric.tag === null || parseFloat(metric.tag) === admin_data.id)) {
                                        let rf_index = values[8].data.findIndex(i => i.id === metric.data1[0].rf)
                                        if (rf_index !== -1 && (!(values[8].data[rf_index].tags != null) || (values[8].data[rf_index].tags && !values[8].data[rf_index].tags.length)  || (values[8].data[rf_index].tags && values[8].data[rf_index].tags.includes(admin_data.id)))) {
                                            optional_rf.push({ cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric })
                                            // df_list.push(values[8].data[rf_index])
                                        }
                                    }
                                })
                            }
                        }
                        )
                    }
                })
                setDfList(df_list)
                // DF
                shapedCategory.flatMap(i => i.newTopics).forEach((top) => {
                    if (values[0].data[0].topic_ids.includes(top.id) && (top.tag === null || parseFloat(top.tag) === admin_data.id)) {
                        top.newMetrics.forEach((met) => {
                          

                            if ((curatedIndicators.includes(met.id) || !tvsSubAdmin) && values[0].data[0].metric_ids.includes(met.id) && (met.tag === null || parseFloat(met.tag) === admin_data.id) && met.newDataPoints !== undefined) {
                                met.newDataPoints.forEach((ndp) => {
                                  
                                    if (Array.isArray(ndp.data1) && ndp.data1[0].datasource && typeof ndp.data1[0].datasource === 'number' && dcf_list.map(i => i.id).includes(ndp.data1[0].datasource)) {
                                        let dcf_index = dcf_list.findIndex(i => i.id === ndp.data1[0].datasource)
                                   
                                        if (!assinged_dcf.map(i => i.id).includes(ndp.data1[0].datasource) && dcf_index !== -1 && ( !(dcf_list[dcf_index].tags != null) || (dcf_list[dcf_index].tags && !dcf_list[dcf_index].tags.length)  || dcf_list[dcf_index].tags.includes(admin_data.id))) {
                                            let dcf = { ...dcf_list[dcf_index] }
                                           
                                            dcf.section = findKeyById(tvssection, met.id)
                                            assinged_dcf.push(dcf)
                                        }
                                    }
                                })
                            }
                        })
                    }
                })
                console.log(assinged_dcf)
                let isApprover = values[16].data.filter(i => i.user_id === login_data.id && i.tier1_id === 0 && i.tier2_id === null && i.tier3_id === null && i.roles.some(x => [2, 6].includes(x))).length

                for (const dcfass of assinged_dcf) {
                    let entity_index = assigned_dcf_entity.findIndex(i => i.dcfId === dcfass.id)

                    if (entity_index !== -1) {
                        if (login_data.role !== 'clientadmin') {
                            assigned_dcf_entity[entity_index].tier0_ids = isApprover ? assigned_dcf_entity[entity_index].tier0_ids : []
                            assigned_dcf_entity[entity_index].tier1_ids = assigned_dcf_entity[entity_index].tier1_ids.filter(i => shapedSite.map(x => x.id).includes(i)).filter(i => checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, 1, i, values[16].data.filter(x => x.user_id === login_data.id), adminShapedSite).includes(login_data.id))
                            assigned_dcf_entity[entity_index].tier2_ids = assigned_dcf_entity[entity_index].tier2_ids.filter(i => shapedSite.flatMap(x => x.locationTwos).map(x => x.id).includes(i)).filter(i => checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, 2, i, values[16].data.filter(x => x.user_id === login_data.id), adminShapedSite).includes(login_data.id))
                            assigned_dcf_entity[entity_index].tier3_ids = assigned_dcf_entity[entity_index].tier3_ids.filter(i => shapedSite.flatMap(x => x.locationTwos.flatMap(y => y.locationThrees)).map(x => x.id).includes(i)).filter(i => checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, 3, i, values[16].data.filter(x => x.user_id === login_data.id), adminShapedSite).includes(login_data.id))
                        }
                        dcfass.entity = { ...assigned_dcf_entity[entity_index], title: dcfass.title }
                    } else {
                        dcfass.entity = { dcfId: dcfass.id, tier1_ids: [], tier2_ids: [], tier3_ids: [], tier0_ids: [], title: dcfass.title }
                    }
                }
                for (const srfass of assinged_srf) {
                    let entity_index = assigned_srf_entity.findIndex(i => i.srfId === srfass.id)
                    if (entity_index !== -1) {
                        if (login_data.role !== 'clientadmin') {
                            assigned_srf_entity[entity_index].tier0_ids = isApprover ? assigned_srf_entity[entity_index].tier0_ids : []
                            assigned_srf_entity[entity_index].tier1_ids = assigned_srf_entity[entity_index].tier1_ids.filter(i => shapedSite.map(x => x.id).includes(i)).filter(i => checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, 1, i, values[16].data.filter(x => x.user_id === login_data.id), adminShapedSite).includes(login_data.id))
                            assigned_srf_entity[entity_index].tier2_ids = assigned_srf_entity[entity_index].tier2_ids.filter(i => shapedSite.flatMap(x => x.locationTwos).map(x => x.id).includes(i)).filter(i => checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, 2, i, values[16].data.filter(x => x.user_id === login_data.id), adminShapedSite).includes(login_data.id))
                            assigned_srf_entity[entity_index].tier3_ids = assigned_srf_entity[entity_index].tier3_ids.filter(i => shapedSite.flatMap(x => x.locationTwos.flatMap(y => y.locationThrees)).map(x => x.id).includes(i)).filter(i => checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, 3, i, values[16].data.filter(x => x.user_id === login_data.id), adminShapedSite).includes(login_data.id))
                        }
                        srfass.entity = { ...assigned_srf_entity[entity_index], title: srfass.title }
                    } else {
                        srfass.entity = { srfId: srfass.id, tier1_ids: [], tier2_ids: [], tier3_ids: [], tier0_ids: [], title: srfass.title }
                    }
                }
                for (const dfass of required_rf) {

                    let entity_index = assinged_rf_entity.findIndex(i => i.dfId === dfass.data1[0].rf)
                    if (entity_index !== -1) {
                        if (login_data.role !== 'clientadmin') {
                            assinged_rf_entity[entity_index].tier0_ids = isApprover ? assinged_rf_entity[entity_index].tier0_ids : []
                            assinged_rf_entity[entity_index].tier1_ids = assinged_rf_entity[entity_index].tier1_ids.filter(i => shapedSite.map(x => x.id).includes(i)).filter(i => checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, 1, i, values[16].data.filter(x => x.user_id === login_data.id), adminShapedSite).includes(login_data.id))
                            assinged_rf_entity[entity_index].tier2_ids = assinged_rf_entity[entity_index].tier2_ids.filter(i => shapedSite.flatMap(x => x.locationTwos).map(x => x.id).includes(i)).filter(i => checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, 2, i, values[16].data.filter(x => x.user_id === login_data.id), adminShapedSite).includes(login_data.id))
                            assinged_rf_entity[entity_index].tier3_ids = assinged_rf_entity[entity_index].tier3_ids.filter(i => shapedSite.flatMap(x => x.locationTwos.flatMap(y => y.locationThrees)).map(x => x.id).includes(i)).filter(i => checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, 3, i, values[16].data.filter(x => x.user_id === login_data.id), adminShapedSite).includes(login_data.id))
                        }
                        dfass.entity = { ...assinged_rf_entity[entity_index], title: dfass.title }
                    } else {
                        dfass.entity = { dfId: dfass.data1[0].rf, tier1_ids: [], tier2_ids: [], tier3_ids: [], tier0_ids: [], title: dfass.title, type: 1 }
                    }
                }

                for (const dfass of optional_rf.filter((i) => { return !required_rf.map(i => i.id).includes(i.id) })) {
                    console.log(dfass)
                    let entity_index = assinged_rf_entity.findIndex(i => i.dfId === dfass.data1[0].rf)
                    if (entity_index !== -1) {
                        dfass.entity = { ...assinged_rf_entity[entity_index], title: dfass.title }
                    } else {
                        dfass.entity = { dfId: dfass.data1[0].rf, tier1_ids: [], tier2_ids: [], tier3_ids: [], tier0_ids: [], title: dfass.title, type: 2 }
                    }
                }
                setAssignedDcfList(assinged_dcf)
                setAssignedSrfList(assinged_srf)

                setAssFramework(values[10].data.filter((i) => { return admin_data.information.report.includes(i.id) }))
                setAssFramework2(values[10].data)
                // setRequiredList2(optional_rf.filter((i) => { return !required_rf.map(i => i.id).includes(i.id) }))
                // setRequiredList2BK(optional_rf.filter((i) => { return !required_rf.map(i => i.id).includes(i.id) }))
                setRequiredList(required_rf)
                setRequiredListBK(required_rf)
            }
            setLoading(false)
        })
    }, [])
    function findKeyById(tvssection, id) {
        for (const key in tvssection) {
            if (tvssection[key].includes(id)) {
                return parseFloat(key);
            }
        }
        return 0;
    }
    const dcfTitleTemplate = (rowData) => {
        console.log(rowData)
        // <span className='m-2'> <i className="pi pi-eye cur-pointer"  /> </span>
        return (
            <div className="clr-navy cur-pointer text-underline fw-6" onClick={() => { window.open(window.location.origin + '/dcf_preview/' + rowData.id) }}>
                {rowData.title}
            </div>
        )
    }
    const srfTitleTemplate = (rowData) => {
        console.log(rowData)
        // <span className='m-2'> <i className="pi pi-eye cur-pointer"  /> </span>
        return (
            <div className="clr-navy cur-pointer text-underline fw-6" onClick={() => { window.open(window.location.origin + '/cf_preview_view').sessionStorage.setItem('cfpreview', rowData.id) }}>
                {rowData.title}
            </div>
        )
    }
    const addQNEntityTemplate = (rowData) => {
        let count = 0
        if (rowData.entity) {
            count = rowData.entity.tier0_ids.length + rowData.entity.tier1_ids.length + rowData.entity.tier2_ids.length + rowData.entity.tier3_ids.length
        }
        return (
            <div className="flex justify-content-center">
                <div className="flex align-items-center row">
                    <div className='flex justify-content-center fw-7 fs-16 clr-navy' >  {count}</div>
                    <label className="fw-6 fs-14 cur-pointer clr-navy flex text-underline justify-content-center"
                        onClick={() => {

                            setSelectedDcfEntity(rowData.entity);
                            setDcfEntityDialog(true);
                        }}>
                        Add More

                    </label>
                </div>
            </div>
        )
    }
    const formTemplate = (rowData) => {



        return (<div className="cur-pointer text-underline fw-7 clr-navy " onClick={() => { openAllEntityUserAss(rowData) }}>{rowData.form}</div>)
    }
    const addSRFEntityTemplate = (rowData) => {

        let count = 0
        if (rowData.entity) {
            count = rowData.entity.tier0_ids.length + rowData.entity.tier1_ids.length + rowData.entity.tier2_ids.length + rowData.entity.tier3_ids.length
        }
        return (
            <div className="flex justify-content-center">
                <div className="flex align-items-center row">
                    <div className='flex justify-content-center fw-7 fs-16 clr-navy' >  {count}</div>
                    <label className="fw-6 fs-14 cur-pointer clr-navy flex text-underline justify-content-center"
                        onClick={() => {
                            setSelectedSrfEntity(rowData.entity); setSrfEntityDialog(true)
                        }}>
                        Add More

                    </label>
                </div>
            </div>

        )

    }
    const downloadAssignemntReport = (e) => {
        // Get the data to be exported
        let data2excel = sumdtref.current.getVirtualScroller().props.items;

        // Process each item in the data2excel array
        data2excel.forEach((item) => {
            // Map user IDs to their corresponding user information
            let users = item.srfId ? [admin_data, ...supplierList_] : [admin_data, ...userList_];

            // Update reporter_ids
            let reporters = item.reporter_ids.map(id => users.find(user => user.id === id));
            item.reporter_ids = reporters.filter(user => user).map(user => user.role === 'clientadmin' ? 'EnterpriseAdmin' : user.information.empname);

            // Update approver_ids

            item.approver_ids = []

            // Update reviewer_ids if they exist, otherwise set to ['Self']
            if (item.reviewer_ids && item.reviewer_ids.length) {
                let reviewers = item.reviewer_ids.map(id => users.find(user => user.id === id));
                item.reviewer_ids = reviewers.filter(user => user).map(user => user.role === 'clientadmin' ? 'EnterpriseAdmin' : user.information.empname);
            } else {
                item.reviewer_ids = ['Self'];
            }
        });

        // Prepare the data for export
        const ws = XLSX.utils.aoa_to_sheet([]);
        let rowIndex = 0;
        let merges = [];

        // Add headers
        const headers = ["Type", "Form", "Entity", "Reporting Frequency", "Start Month", "End Month", "Reporter", "Reviewer", "Approver"];
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: rowIndex++ });

        // Add data rows
        data2excel.forEach(item => {
            const baseRow = [
                item.type = item.dcfId ? 'Quantitaive' : item.dfId ? 'Qualitative' : 'Supplier',
                item.form,
                item.ent,
                frequency_list.find(i => i.id === item.frequency).name,
                DateTime.fromISO(item.start_date, { zone: 'utc' }).plus({ months: 1 }).toFormat('LLL-yyyy'),
                DateTime.fromISO(item.end_date ? item.end_date : DateTime.utc(), { zone: 'utc' }).plus({ months: 1 }).toFormat('LLL-yyyy'),
                '', // Placeholder for Reporter
                '', // Placeholder for Reviewer
                ''  // Placeholder for Approver
            ];

            // Determine the maximum length among reporter_ids, reviewer_ids, and approver_ids
            let maxRows = Math.max(item.reporter_ids.length, item.reviewer_ids.length, item.approver_ids.length);

            if (maxRows > 0) {
                for (let i = 0; i < maxRows; i++) {
                    const optionRow = baseRow.slice();
                    if (i < item.reporter_ids.length) {
                        optionRow[6] = item.reporter_ids[i];
                    }
                    if (i < item.reviewer_ids.length) {
                        optionRow[7] = item.reviewer_ids[i];
                    }
                    if (i < item.approver_ids.length) {
                        optionRow[8] = item.approver_ids[i];
                    }
                    XLSX.utils.sheet_add_aoa(ws, [optionRow], { origin: rowIndex++ });
                }

                // Merge the Type and Form cells for the maximum rows
                merges.push({ s: { r: rowIndex - maxRows, c: 0 }, e: { r: rowIndex - 1, c: 0 } });
                merges.push({ s: { r: rowIndex - maxRows, c: 1 }, e: { r: rowIndex - 1, c: 1 } });
                merges.push({ s: { r: rowIndex - maxRows, c: 2 }, e: { r: rowIndex - 1, c: 2 } });
                merges.push({ s: { r: rowIndex - maxRows, c: 3 }, e: { r: rowIndex - 1, c: 3 } });
                merges.push({ s: { r: rowIndex - maxRows, c: 4 }, e: { r: rowIndex - 1, c: 4 } });
                merges.push({ s: { r: rowIndex - maxRows, c: 5 }, e: { r: rowIndex - 1, c: 5 } });
            } else {
                XLSX.utils.sheet_add_aoa(ws, [baseRow], { origin: rowIndex++ });
            }
        });

        // Apply merges to the worksheet
        ws['!merges'] = merges;

        // Create workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Write workbook and save to file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        FileSaver.saveAs(dataBlob, 'Data_Assignment_on_' + DateTime.utc().toLocal().toFormat('dd_LLL_yyyy_') + '.xlsx');
    };



    const lastResponse = (rowData) => {

        let text = 'NA'
        let data = rowData.data1[0]

        let index = rfresponse.findLastIndex((i) => { return i.dfId === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && JSON.stringify(rowData.reporting_period) === JSON.stringify(i.reporting_period) && i.type >= 1 })

        if (index !== -1) {
            text = moment.utc(rfresponse[index].reporter_modified_on).format('DD MMMM YYYY')

        }
        return (
            <>
                {text}
            </>
        )
    }
    const historyTemplate = (rowData) => {
        let text = true
        let data = rowData.data1[0]
        let mergeData = []
        console.log(rowData)
        let index = rfresponse.findLastIndex((i) => { return i.dfId === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && JSON.stringify(rowData.reporting_period) === JSON.stringify(i.reporting_period) })

        console.log(index, rfresponse)
        if (index !== -1) {
            text = false
            mergeData = rfresponse.filter((i) => { return i.dfId === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && JSON.stringify(rowData.reporting_period) === JSON.stringify(i.reporting_period) })
            mergeData.forEach((i) => {
                i.top_title = rowData.top_title
                i.cat_title = rowData.cat_title
            })
            console.log(mergeData)
        }
        return (
            <>
                {text ?
                    <span>NA</span> :
                    <a className="cur-pointer" onClick={() => { setHistoryData(mergeData); activeRowData = rowData; setHistoryDialog(true) }}>View History</a>
                }

            </>
        )
    }
    const statusTemplate = (rowData) => {

        let text = 'Not Responded'
        let data = rowData.data1[0]

        let index = rfresponse.findLastIndex((i) => { return i.dfId === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && JSON.stringify(rowData.reporting_period) === JSON.stringify(i.reporting_period) })

        if (index !== -1) {
            if (rfresponse[index].type === 0 && rfresponse[index].reject) {
                text = 'Rejected'
            } else if (rfresponse[index].type === 0 && !rfresponse[index].reject) {
                text = 'Drafted'
            } else if (rfresponse[index].type === 3) {
                text = 'Approved'
            } else {
                text = 'Under Approval'
            }

        }
        return (
            <>
                {text}
            </>
        )
    }
    const rrTemplate = (rowData) => {


        let data = rowData.data1[0], oldData = { id: null }, id = 0, show = true

        let index = rfresponse.findLastIndex((i) => { return i.dfId === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId && JSON.stringify(rowData.reporting_period) === JSON.stringify(i.reporting_period) })
        if (index !== -1) {

            oldData = rfresponse[index]

        }

        return (
            < >
                <label className={'cur-pointer text-underline clr-navy fw-5'} onClick={() => { tvsSubAdmin ? window.open(window.location.origin + '/df_input_entry/').sessionStorage.setItem('dfpreview', JSON.stringify({ id: rowData.data1[0].rf, submitId: oldData.id, params: { state: { data: rowData } } })) : window.open(window.location.origin + '/df_preview/' + rowData.data1[0].rf) }}>       {rowData.data1[0].title} </label>

            </>
        )
    }
    const addQLEntityTemplate = (rowData) => {
        console.log(rowData)
        let count = 0;
        if (rowData.entity) {
            count =
                rowData.entity.tier0_ids.length +
                rowData.entity.tier1_ids.length +
                rowData.entity.tier2_ids.length +
                rowData.entity.tier3_ids.length;
        }
        return (

            <div className="flex justify-content-center">
                <div className="flex align-items-center row">
                    <div className='flex justify-content-center fw-7 fs-16 clr-navy' >  {count}</div>
                    <label className="fw-6 fs-14 cur-pointer clr-navy flex text-underline justify-content-center"
                        onClick={() => {
                            let approver = checkRoleAccessByRoleIds(
                                userList_.map((i) => i.id),
                                [3],
                                0,
                                null,
                                roleasslist,
                                rawsitelist,
                                admin_data.id
                            )
                                .map((i) => userList_.find((x) => x.id === i))
                                .filter((i) => i);


                            setApproverList(
                                approver.map((i) => ({ name: i.information.empname, id: i.id }))
                            );
                            setSelectedDfEntity(rowData.entity);
                            setDfEntityDialog(true);
                        }}
                    >
                        Add More

                    </label>
                </div>
            </div>
        );
    };
    const titleTemplate = (rowData) => {
        let oldData = []
        return (
            < >
                <Tooltip className="tag-tooltip" target={".tags" + rowData.id} position={'top'} autoHide={true}> {rowData.overallTags.map((i, j) => {
                    if (i.length !== 0) {
                        return (
                            <>
                                <label style={{ color: 'black', display: 'flex' }}> {
                                    j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                }
                                </label>
                                {
                                    i.map((tag, k) => {

                                        return (
                                            <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                        )

                                    })
                                }
                                <div style={{ marginBottom: 10 }} />
                            </>
                        )
                    }
                })} </Tooltip>
                <div style={{ alignItems: 'center' }} >{rowData.title} <i className={"material-icons " + "tags" + rowData.id} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

            </>
        )
    }
    const addEntityUserTemplate = (rowData) => {
        return (
            <div onClick={() => { openDCFEntityUserAss(rowData) }}>
                Add
            </div>
        )
    }
    const openSRFEntityUserAss = (rowData) => {
        let rf_index = srfentityuserass.findIndex((i) => { return i.id === rowData.assId })

        if (rf_index === -1) {
            let end_date_index = srfentityuserass.sort((a, b) => { return b.id - a.id }).filter(i => i.srfId === rowData.id && (i.tier3_id === rowData.tier3_id || i.tier3_id === null) && (i.tier2_id === rowData.tier2_id || i.tier2_id === null) && (i.tier1_id === rowData.tier1_id || i.tier1_id === null) && (i.tier0_id === rowData.tier0_id || i.tier0_id === null))

            if (end_date_index.length) {
                setMinDate(end_date_index[0].end_date)
            } else {
                setMinDate(null)
            }


            setSrfEntityUserObj({ [`tier${rowData.level}_id`]: rowData.locationId, level: rowData.level, locationId: rowData.locationId, srfId: rowData.id, frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [] })
        } else {


            let oldObj = { ...srfentityuserass[rf_index] }
            if (typeof oldObj.start_date !== 'object') {
                oldObj.start_date = DateTime.fromISO(oldObj.start_date, { zone: 'utc' }).toLocal().toJSDate()
            }

            if (oldObj.end_date !== undefined && oldObj.end_date !== null && typeof oldObj.end_date !== 'object') {
                oldObj.disable_end = true
                oldObj.end_date = DateTime.fromISO(oldObj.end_date, { zone: 'utc' }).toLocal().toJSDate()
            } else if (oldObj.end_date !== undefined && oldObj.end_date !== null) {
                oldObj.disable_end = true
            }
            console.log(oldObj)
            setSrfEntityUserObj(oldObj)
        }
        let reporters = supplierList_
        let reviewer = checkRoleAccessByRoleIds(userList_.map(i => i.id), [4], rowData.level, rowData.locationId, roleasslist, rawsitelist, admin_data.id).map(i => userList_.find(x => x.id === i)).filter(i => i)
        let approver = checkRoleAccessByRoleIds(userList_.map(i => i.id), [3], rowData.level, rowData.locationId, roleasslist, rawsitelist, admin_data.id).map(i => userList_.find(x => x.id === i)).filter(i => i)
        console.log(userList_)
        setSupplierList(reporters.map(i => ({ name: i.information.supplierName, id: i.id })))
        setReviewerList(reviewer.map(i => ({ name: i.information.empname, id: i.id })))
        setApproverList(approver.map(i => ({ name: i.information.empname, id: i.id })))
        setSrfEntityUserDialog(true)
    }
    const openAllEntityUserAss = (rowData) => {
        let oldObj = { ...rowData }
        if (typeof oldObj.start_date !== 'object') {
            oldObj.start_date = DateTime.fromISO(oldObj.start_date, { zone: 'utc' }).toLocal().toJSDate()
        }

        if (oldObj.end_date !== undefined && oldObj.end_date !== null && typeof oldObj.end_date !== 'object') {
            oldObj.disable_end = true
            oldObj.end_date = DateTime.fromISO(oldObj.end_date, { zone: 'utc' }).toLocal().toJSDate()
        } else if (oldObj.end_date !== undefined && oldObj.end_date !== null) {
            oldObj.disable_end = true
        }
        setAllEntityUserObj(oldObj)
        setAllEntityUserDialog(true)
    }
    const openDCFEntityUserAss = (rowData) => {
        let rf_index = dcfentityuserass.findIndex((i) => { return i.id === rowData.assId })
        console.log(rowData)
        if (rf_index === -1) {
            let end_date_index = dcfentityuserass.sort((a, b) => { return b.id - a.id }).filter(i => i.dcfId === rowData.id && (i.tier3_id === rowData.tier3_id || i.tier3_id === null) && (i.tier2_id === rowData.tier2_id || i.tier2_id === null) && (i.tier1_id === rowData.tier1_id || i.tier1_id === null) && (i.tier0_id === rowData.tier0_id || i.tier0_id === null))
            console.log(end_date_index)
            if (end_date_index.length) {

                setMinDate(end_date_index[0].end_date)
            } else {
                setMinDate(null)
            }

            setStdList(stdlistBk.filter((i) => { return i.dcf_ids !== null && i.dcf_ids.includes(rowData.id) }))
            // let newObj = {}
            // if (rowData.tier3_id !== undefined && rowData.tier3_id !== null) {
            //     newObj['tier3_id'] = rowData.tier3_id
            // } else if (rowData.tier2_id !== undefined && rowData.tier2_id !== null) {
            //     newObj['tier2_id'] = rowData.tier2_id
            // } else if (rowData.tier1_id !== undefined && rowData.tier1_id !== null) {
            //     newObj['tier1_id'] = rowData.tier1_id
            // } else if (rowData.tier0_id !== undefined && rowData.tier0_id !== null) {
            //     newObj['tier0_id'] = rowData.tier0_id
            // }

            setDcfEntityUserObj({ [`tier${rowData.level}_id`]: rowData.locationId, level: rowData.level, locationId: rowData.locationId, dcfId: rowData.id, frequency: null, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], standard: null })
        } else {

            setStdList(stdlistBk.filter((i) => { return i.dcf_ids !== null && i.dcf_ids.includes(rowData.dcfId) }))
            let oldObj = { ...dcfentityuserass[rf_index] }
            if (typeof oldObj.start_date !== 'object') {
                oldObj.start_date = DateTime.fromISO(oldObj.start_date, { zone: 'utc' }).toLocal().toJSDate()
            }

            if (oldObj.end_date !== undefined && oldObj.end_date !== null && typeof oldObj.end_date !== 'object') {
                oldObj.disable_end = true
                oldObj.end_date = DateTime.fromISO(oldObj.end_date, { zone: 'utc' }).toLocal().toJSDate()
            } else if (oldObj.end_date !== undefined && oldObj.end_date !== null) {
                oldObj.disable_end = true
            }
            console.log(oldObj)
            setDcfEntityUserObj(oldObj)
        }
        let reporters = checkRoleAccessByRoleIds(userList_.map(i => i.id), [1], rowData.level, rowData.locationId, roleasslist, rawsitelist, admin_data.id).map(i => userList_.find(x => x.id === i)).filter(i => i)
        let reviewer = checkRoleAccessByRoleIds(userList_.map(i => i.id), [4], rowData.level, rowData.locationId, roleasslist, rawsitelist, admin_data.id).map(i => userList_.find(x => x.id === i)).filter(i => i)
        let approver = checkRoleAccessByRoleIds(userList_.map(i => i.id), [3], rowData.level, rowData.locationId, roleasslist, rawsitelist, admin_data.id).map(i => userList_.find(x => x.id === i)).filter(i => i)
        console.log(userList_)
        setUserList(reporters.map(i => ({ name: i.information.empname, id: i.id })))
        setReviewerList(reviewer.map(i => ({ name: i.information.empname, id: i.id })))
        setApproverList(approver.map(i => ({ name: i.information.empname, id: i.id })))
        setDcfEntityUserDialog(true)
    }
    const openDFEntityUserAss = (rowData) => {
        let rf_index = dfentityuserass.findIndex((i) => { return i.id === rowData.assId })

        if (rf_index === -1) {
            let end_date_index = dfentityuserass.sort((a, b) => { return b.id - a.id }).filter(i => i.dfId === rowData.data1[0].rf && (i.tier3_id === rowData.tier3_id || i.tier3_id === null) && (i.tier2_id === rowData.tier2_id || i.tier2_id === null) && (i.tier1_id === rowData.tier1_id || i.tier1_id === null) && (i.tier0_id === rowData.tier0_id || i.tier0_id === null))

            if (end_date_index.length) {
                setMinDate(end_date_index[0].end_date)
            } else {
                setMinDate(null)
            }

            setDfEntityUserObj({ [`tier${rowData.level}_id`]: rowData.locationId, level: rowData.level, locationId: rowData.locationId, dfId: rowData.data1[0].rf, frequency: 4, start_date: null, end_date: null, reporter_ids: [], reviewer_ids: [], approver_ids: [], type: rowData.type })
        } else {


            let oldObj = { ...dfentityuserass[rf_index] }
            if (typeof oldObj.start_date !== 'object') {
                oldObj.start_date = DateTime.fromISO(oldObj.start_date, { zone: 'utc' }).toLocal().toJSDate()
            }

            if (oldObj.end_date !== undefined && oldObj.end_date !== null && typeof oldObj.end_date !== 'object') {
                oldObj.disable_end = true
                oldObj.end_date = DateTime.fromISO(oldObj.end_date, { zone: 'utc' }).toLocal().toJSDate()
            } else if (oldObj.end_date !== undefined && oldObj.end_date !== null) {
                oldObj.disable_end = true
            }
            console.log(oldObj)
            setDfEntityUserObj(oldObj)
        }
        let reporters = checkRoleAccessByRoleIds(userList_.map(i => i.id), [1], rowData.level, rowData.locationId, roleasslist, rawsitelist, admin_data.id).map(i => userList_.find(x => x.id === i)).filter(i => i)
        let reviewer = checkRoleAccessByRoleIds(userList_.map(i => i.id), [4], rowData.level, rowData.locationId, roleasslist, rawsitelist, admin_data.id).map(i => userList_.find(x => x.id === i)).filter(i => i)
        let approver = checkRoleAccessByRoleIds(userList_.map(i => i.id), [3], rowData.level, rowData.locationId, roleasslist, rawsitelist, admin_data.id).map(i => userList_.find(x => x.id === i)).filter(i => i)
        setFrequencyMonths(generateMonthArray(4))
        setUserList(reporters.map(i => ({ name: i.information.empname, id: i.id })))
        setReviewerList(reviewer.map(i => ({ name: i.information.empname, id: i.id })))
        setApproverList(approver.map(i => ({ name: i.information.empname, id: i.id })))
        setDfEntityUserDialog(true)
    }
    function groupByMultipleKeys(array, keys) {
        return array.reduce((result, item) => {
            // Create a composite key by joining the values of the specified keys with a hyphen
            const compositeKey = keys.map(key => item[key]).join('-');

            // If the group doesn't exist yet, create it
            if (!result[compositeKey]) {
                result[compositeKey] = [];
            }

            // Push the current object into the correct group
            result[compositeKey].push(item);

            return result;
        }, {});
    }
    function countOccurrences(data) {
        let count = 0;
        console.log(data)
        for (const key in data) {
            if (Array.isArray(data[key])) {
                let len = data[key].filter(item => {
                    return item.assId !== undefined
                }).length
                if (len !== 0) {
                    count++
                }
            }
        }

        return count;
    }

    const sectionRowFilter = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={assignedsection}

                onChange={(e) => options.filterCallback(e.value)}
                optionLabel="title"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const sectionTemplate = (rowData) => {
        let txt = ''
        let sec = sectionlist.find(i => i.id === rowData.section)
        if (sec) {
            txt = sec.title
        }
        return <>{txt}</>
    }
    const dcfIdRowFilter = (options) => {
        return (
            <MultiSelect
                value={options.value}


                options={assigneddcflist}

                onChange={(e) => options.filterCallback(e.value)}
                optionLabel="title"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: "14rem" }}
            />
        );
    };
    const dcfIdTemplate = (rowData) => {

        return <>{rowData?.id || '-'}</>
    }
    const viewDcfEntityTemplate = (rowData) => {
        console.log(rowData, rowData.section)
        let index = 0, count = 0, assCount = 0
        let rf_index = dcfentityass.findIndex((i) => { return i.dcfId === rowData.id })
        if (rf_index !== -1) {
            let findIndex = dcfentityass.filter((i) => { return i.dcfId === rowData.id }).length
            if (findIndex) {
                console.log(dcfentityass[rf_index])
                count = dcfentityass[rf_index].tier2_ids.length + dcfentityass[rf_index].tier3_ids.length + dcfentityass[rf_index].tier1_ids.length + dcfentityass[rf_index].tier0_ids.length
                if (count) {
                    index = count
                }
            }

        }
        if (index !== 0) {
            let groupData = groupByMultipleKeys(dcfEntityToUserAssignment([{ ...dcfentityass[rf_index], title: rowData.title }]).map((i) => { return getDcf(i) }).filter(i => i !== null), ['tier0_id', 'tier1_id', 'tier2_id', 'tier3_id'])
            assCount = countOccurrences(groupData)
        }



        return (
            <div className="flex justify-content-center">
                {index !== 0 ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <div className='flex justify-content-center fw-7 fs-16 clr-navy' style={{ color: assCount !== index && 'red' }} >  {assCount + ' / ' + index}</div>
                        <label className="fw-6 fs-14 cur-pointer clr-navy text-underline flex justify-content-center"
                            onClick={() => {
                                setDcfEntityViewDialog(true);
                                setSelectedDcfEntity({
                                    ...dcfentityass[rf_index],
                                    title: rowData.title,
                                });
                            }}


                        >  Assign </label>
                        {/* <label onClick={() => { setDcfEntityViewDialog(true); setSelectedDcfEntity({ ...dcfentityass[rf_index], title: rowData.title }) }} className="text-underline cur-pointer col-12" >view</label> */}
                    </div>
                ) : (
                    <label className='clr-gray-900 font-italic'>Not applicable</label>
                )}
            </div>
        );
    }
    const viewSrfEntityTemplate = (rowData) => {
        let index = 0, count = 0, assCount = 0
        let rf_index = srfentityass.findIndex((i) => { return i.srfId === rowData.id })

        if (rf_index !== -1) {
            let findIndex = srfentityass.filter((i) => { return i.srfId === rowData.id }).length
            if (findIndex) {
                console.log(srfentityass[rf_index])
                let count = srfentityass[rf_index].tier2_ids.length + srfentityass[rf_index].tier3_ids.length + srfentityass[rf_index].tier1_ids.length + srfentityass[rf_index].tier0_ids.length
                if (count) {
                    index = count
                }
            }

        }
        if (index !== 0) {
            let groupData = groupByMultipleKeys(srfEntityToUserAssignment([{ ...srfentityass[rf_index], title: rowData.title }]).map((i) => { return getSrf(i) }).filter(i => i !== null), ['tier0_id', 'tier1_id', 'tier2_id', 'tier3_id'])
            assCount = countOccurrences(groupData)
        }
        return (

            <div className="flex justify-content-center">
                {index !== 0 ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <div className='flex justify-content-center fw-7 fs-16 clr-navy' style={{ color: assCount !== index && 'red' }} >  {assCount + ' / ' + index}</div>
                        <label className="fw-6 fs-14 cur-pointer clr-navy text-underline flex justify-content-center"
                            onClick={() => {
                                setSrfEntityViewDialog(true); setSelectedSrfEntity({ ...srfentityass[rf_index], title: rowData.title })
                            }}


                        >  Assign </label>
                        {/* <label onClick={() => { setDcfEntityViewDialog(true); setSelectedDcfEntity({ ...dcfentityass[rf_index], title: rowData.title }) }} className="text-underline cur-pointer col-12" >view</label> */}
                    </div>
                ) : (
                    <label className='clr-gray-900 font-italic'>Not applicable</label>
                )}
            </div>
        )
    }
    const viewDfEntityTemplate = (rowData) => {
        let index = 0,assCount= 0
        let rf_index = dfentityass.findIndex((i) => { return i.dfId === rowData.data1[0].rf })
        if (rf_index !== -1) {
            let findIndex = dfentityass.filter((i) => { return i.dfId === rowData.data1[0].rf }).length
            if (findIndex) {
                console.log(dfentityass[rf_index])
                let count = dfentityass[rf_index].tier2_ids.length + dfentityass[rf_index].tier3_ids.length + dfentityass[rf_index].tier1_ids.length + dfentityass[rf_index].tier0_ids.length
                if (count) {
                    index = count
                }
            }

        }
        if (index !== 0) {
            let groupData = groupByMultipleKeys(dfEntityToUserAssignment([{ ...dfentityass[rf_index], title: rowData.title }]).map((i) => { return getRf(i) }).filter(i => i !== null), ['tier0_id', 'tier1_id', 'tier2_id', 'tier3_id'])
            assCount = countOccurrences(groupData)
        }
        return (

            <div className="flex justify-content-center">
                {index !== 0 ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <div className='flex justify-content-center fw-7 fs-16 clr-navy' style={{ color: assCount !== index && 'red' }} >  {assCount + ' / ' + index}</div>
                        <label className="fw-6 fs-14 cur-pointer clr-navy text-underline flex justify-content-center"
                            onClick={() => {
                                setDfEnitityViewDialog(true); setSelectedDfEntity({ ...dfentityass[rf_index], title: rowData.title })
                            }}


                        >  Assign </label>
                        {/* <label onClick={() => { setDcfEntityViewDialog(true); setSelectedDcfEntity({ ...dcfentityass[rf_index], title: rowData.title }) }} className="text-underline cur-pointer col-12" >view</label> */}
                    </div>
                ) : (
                    <label className='clr-gray-900 font-italic'>Not applicable</label>
                )}
            </div>
        )
    }
    const getCoverageText_ = (rowData) => {
        let text = 'Not Found'
        console.log(rowData)
        if (rowData.level === 0) {
            text = 'Corporate'
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.locationId)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.locationId })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.level === 3) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.locationId })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }
        return { name: text }
    }
    const getCoverageText = (rowData) => {
        console.log(rowData)
        let text = 'Not Found'
        let id = ''

        if (rowData.tier3_id !== undefined && rowData.tier3_id !== null) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.tier3_id })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        } if (rowData.tier2_id !== undefined && rowData.tier2_id !== null) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.tier2_id })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name

            }

        } else if (rowData.tier1_id !== undefined && rowData.tier1_id !== null) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.tier1_id)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.tier0_id !== undefined && rowData.tier0_id !== null) {
            text = 'Corporate'
        }
        return { name: text }
    }
    const groupEntityTemplate = (option) => {
        console.log(option)
        return (
            <div className="flex align-items-center gap-2">
                {
                    option.items.length ?
                        <div>{option.label + ' (' + option.items.length + ')'}</div>
                        :
                        <div>{option.label}</div>
                }

            </div>
        );
    };
    const itemTemplate = (option) => {
        console.log(option)
        return (
            <div className="flex align-items-center ">

                <div>{option.name}</div>


            </div>
        );
    };
    const getEntityOptions = (item) => {
        let newObj = []

        if (item.tier1_ids.length > 0) {

            let obj = { label: label1, items: [] }
            item.tier1_ids.filter(i => i != 0).forEach((country) => {
                obj.items.push(getCoverageText({ level: 1, locationId: country, tier1_id: country }))
            })
            newObj.push(obj)

        }
        if (item.tier2_ids.length) {
            let obj = { label: label2, items: [] }
            item.tier2_ids.filter(i => i != 0).forEach((city) => {
                obj.items.push(getCoverageText({ level: 2, locationId: city, tier2_id: city }))
            })
            newObj.push(obj)
        }
        if (item.tier3_ids.length) {
            let obj = { label: label3, items: [] }
            item.tier3_ids.filter(i => i != 0).forEach((site) => {
                obj.items.push(getCoverageText({ level: 3, locationId: site, tier3_id: site }))
            })
            newObj.push(obj)
        }
        console.log(newObj, item)
        return newObj



    }
    const getSummaryCount = () => {
        let loc = [...JSON.parse(JSON.stringify(dcfentityuserass.filter(i => assigneddcflist.map(i => i.id).includes(i.dcfId))))]
        return loc.filter(i => i.ent !== 'Not Found').filter(i => (login_data.role === 'clientadmin' || checkRoleAccessByRoleIds([login_data.id], !tvsSubAdmin ? [2, 6] : tvsSubAdminRoles, i.level, i.locationId, roleasslist, rawsitelist).includes(login_data.id))).length
    }
    const setSummaryDatas = () => {
        let loc = [...JSON.parse(JSON.stringify(dcfentityuserass.filter(i => assigneddcflist.map(i => i.id).includes(i.dcfId))))]

        loc.forEach(i => {
            i.ent = getCoverageText(i).name
            if (i.dcfId) {
                i.formtype = 1
                let index = assigneddcflist.findIndex(x => x.id === i.dcfId)
                console.log(assigneddcflist)
                if (index !== -1) {
                    i.form = assigneddcflist[index].title

                } else {
                    console.log(assigneddcflist, i.dcfId)
                    i.form = 'Not Found'
                }

            } else if (i.dfId) {
                let index = dflist.findIndex(x => x.id === i.dfId)
                i.formtype = 2
                console.log(dflist)
                if (index !== -1) {
                    i.form = dflist[index].title

                } else {
                    i.form = 'Not Found'
                }

            } else if (i.srfId) {
                let index = assignedsrflist.findIndex(x => x.id === i.srfId)
                i.formtype = 3
                if (index !== -1) {
                    i.form = assignedsrflist[index].title

                } else {
                    i.form = 'Not Found'
                }

            }
        })

        setSummaryData(loc.filter(i => i.ent !== 'Not Found'))
        forceUpdate()
    }
    const frequencyRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={frequency_list}
                itemTemplate={itemTemplate}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const entityRowFilterTemplate = (options) => {
        let allentity = JSON.parse(JSON.stringify(selectedloclist))
        let entOptions = [...allentity.tier1.map(i => i.name), ...allentity.tier2.map(i => i.name), ...allentity.tier3.map(i => i.name)]
        console.log(entOptions)
        return (
            <MultiSelect
                value={options.value}
                options={entOptions}

                onChange={(e) => options.filterApplyCallback(e.value)}

                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    }
    const typeRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={[{ name: 'Quantitative', id: 1 }, { name: 'Qualitative', id: 2 }, { name: 'Supplier', id: 3 }]}
                itemTemplate={itemTemplate}
                onChange={(e) => options.filterCallback(e.value)}
                optionLabel="name"
                optionValue="id"
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const formRowFilterTemplate = (options) => {
        return (
            <MultiSelect
                value={options.value}
                options={[...assigneddcflist, ...assignedsrflist, ...dflist].map(i => i.title)}
                onChange={(e) => options.filterApplyCallback(e.value)}
                placeholder="Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };
    const coverageTemplate = (rowData) => {

        return <span >{getCoverageText(rowData).name} </span>
    }
    const coverageTemplate_ = (rowData) => {

        return <span >{getCoverageText(rowData).name} </span>
    }
    const formTypeTemplate = (rowData) => {
        let type = ''
        console.log(rowData)
        if (rowData.dcfId) {
            type = 'Quantitative'
        } else if (rowData.dfId) {
            type = 'Qualitative'
        } else if (rowData.srfId) {
            type = 'Supplier'
        }
        return <span className='flex justify-content-center' >{type} </span>
    }
    const frequencyTemplate = (rowData) => {
        let text = ""

        let freq_index = frequency_list.findIndex(i => i.id === rowData.frequency)
        if (freq_index !== -1) {
            text = frequency_list[freq_index].name
        }
        return <span className='flex justify-content-center'>{text} </span>
    }
    const startDateTemplate = (rowData) => {
        console.log(rowData)
        return (
            <div className='flex justify-content-center'>
                {
                    (rowData.start_date === undefined || rowData.start_date === null) ?
                        <span></span> :
                        <span>{DateTime.fromISO(rowData.start_date, { zone: 'utc' }).toLocal().toFormat('LLL-yyyy')}</span>
                }
            </div>
        )

    }
    const endDateTemplate = (rowData) => {
        return (
            <div className='flex justify-content-center'>
                {
                    (rowData.end_date === undefined || rowData.end_date === null) ?
                        <span></span> :
                        <span>{DateTime.fromISO(rowData.end_date, { zone: 'utc' }).toLocal().toFormat('LLL-yyyy')}</span>
                }
            </div>
        )
    }
    const reporterListTemplate = (rowData) => {
        const reporterName = rowData?.reporter_ids?.length ? userList?.find(
            (i) => i?.id === rowData?.reporter_ids[0]
        )?.name : ''
        const reportersNameList = rowData?.reporter_ids?.length ? userList
            ?.filter((user) => rowData?.reporter_ids.includes(user?.id))
            .map((item) => item.name) : []

        return (
            <div className="flex justify-content-start">
                {rowData?.reporter_ids === undefined ? (
                    <span></span>
                ) : (
                    <span>
                        <Tooltip target=".reporters">
                            {reportersNameList.map(
                                (name, index) =>
                                    index !== 0 && (
                                        <>
                                            <Badge severity="info" value={name[0]} />
                                            <span className="ms-3">{name}</span>
                                        </>
                                    )
                            )}
                        </Tooltip>
                        {reporterName && <Badge severity="info" value={reporterName[0]} />}
                        {reporterName}
                        {rowData?.reporter_ids?.length - 1 > 0 ? (
                            <span
                                className="font-bold reporters"
                                style={{ cursor: "pointer" }}
                                data-pr-position="bottom"
                                data-pr-autohide={true}
                            >
                                {" "}
                                + {rowData?.reporter_ids?.length - 1}
                            </span>
                        ) : (
                            ""
                        )}
                    </span>
                )}
            </div>
        );
    };
    const reviewerListTemplate = (rowData) => {
        const reviewerName = rowData?.reviewer_ids?.length ? reviewerList?.find(
            (i) => i?.id === rowData?.reviewer_ids[0]
        )?.name : ''
        const reviewersNameList = rowData?.reviewer_ids?.length ? reviewerList
            ?.filter((reviewer) => rowData?.reviewer_ids.includes(reviewer?.id))
            .map((item) => item.name) : []
        return (
            <div className="flex justify-content-start">
                {rowData?.reviewer_ids === undefined ? (
                    <span></span>
                ) : (
                    <span>
                        <Tooltip target=".reviewers">
                            {reviewersNameList.map(
                                (name, index) =>
                                    index !== 0 && (
                                        <>
                                            <Badge severity="info" value={name[0]} />
                                            <span className="ms-3">{name}</span>
                                        </>
                                    )
                            )}
                        </Tooltip>
                        {reviewerName && <Badge severity="info" value={reviewerName[0]} />}
                        {reviewerName}
                        {rowData?.reviewer_ids?.length - 1 > 0 ? (
                            <span
                                className="font-bold reviewers"
                                style={{ cursor: "pointer" }}
                                data-pr-position="bottom"
                            >
                                {" "}
                                + {rowData?.reviewer_ids?.length - 1}
                            </span>
                        ) : (
                            ""
                        )}
                    </span>
                )}
            </div>
        );
    };

    const dcfActionBodyTemplate = (rowData) => {

        return (
            <>
                {rowData.assId === undefined ?
                    <i className="pi pi-plus" onClick={() => { openDCFEntityUserAss(rowData) }}></i>
                    :
                    <i className="pi pi-pencil" onClick={() => { openDCFEntityUserAss(rowData) }}></i>
                }
            </>
        )
    }
    const allActionBodyTemplate = (rowData) => {
        console.log(rowData)
        return (
            <div className="flex justify-content-center">

                <i className="pi pi-eye" onClick={() => { openAllEntityUserAss(rowData) }}></i>


            </div>
        )
    }
    const srfActionBodyTemplate = (rowData) => {

        return (
            <>
                {rowData.assId === undefined ?
                    <i className="pi pi-plus" onClick={() => { openSRFEntityUserAss(rowData) }}></i>
                    :
                    <i className="pi pi-pencil" onClick={() => { openSRFEntityUserAss(rowData) }}></i>
                }
            </>
        )
    }
    const dfActionBodyTemplate = (rowData) => {

        return (
            <>
                {rowData.assId === undefined ?
                    <i className="pi pi-plus" onClick={() => { openDFEntityUserAss(rowData) }}></i>
                    :
                    <i className="pi pi-pencil" onClick={() => { openDFEntityUserAss(rowData) }}></i>
                }
            </>
        )
    }
    const updateDCFEntity = (obj, val) => {

        setSelectedDcfEntity(prev => { return { ...prev, [obj]: val } })
    }
    const updateSRFEntity = (obj, val) => {

        setSelectedSrfEntity(prev => { return { ...prev, [obj]: val } })
    }
    const updateDFEntity = (obj, val) => {

        setSelectedDfEntity(prev => { return { ...prev, [obj]: val } })
    }
    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        console.log(month)
        return frequencyMonths.includes(month) ? <span title={tooltipText}>{shortMonth} </span> : null
    };
    const renderEndMonthContent = (month, shortMonth, longMonth,frequency) => {
        console.log(frequency)
        const tooltipText = `Tooltip for month: ${longMonth}`;
        let allowedMonths = generateEndMonthArray(frequency)
        console.log(allowedMonths)
        return allowedMonths.includes(month) ? <span title={tooltipText}>{shortMonth} </span> : null

    }
    function generateEndMonthArray(frequency) {
        // Convert fymonth to zero-based index
        const startMonth = fymonth - 1;
if(!frequency){
    return []
}
        // Initialize an array to store the end months
        let endMonths = [];

        // Generate the end months based on the frequency
        if (frequency === 4) {
            // Annually, the fiscal year ends in December
            endMonths.push((startMonth + 11) % 12);
        } else if (frequency === 5) {
            // Bi-annually, end months are 5 months after each start month
            endMonths.push((startMonth + 5) % 12, (startMonth + 11) % 12);
        } else {
            for (let i = 0; i < 12; i += frequency) {
                endMonths.push((startMonth + i + frequency - 1) % 12);
            }
        }

        // Sort endMonths to ensure correct order from fiscal start month
        endMonths.sort((a, b) => (a < startMonth ? a + 12 : a) - (b < startMonth ? b + 12 : b));

        return endMonths;
    }
    function generateMonthArray(frequency) {
        // Convert fymonth to zero-based index
        const startMonth = fymonth - 1;
        console.log(fymonth, startMonth)
        // Initialize an array to store the months
        let months = [];

        // Generate the months based on the frequency
        if (frequency === 4) {
            // Annually, only the fiscal month itself
            months.push(startMonth);
        } else if (frequency === 5) {
            // Bi-annually, the fiscal month and 6 months after
            months.push(startMonth, (startMonth + 6) % 12);
        } else {
            for (let i = 0; i < 12; i += frequency) {
                months.push((startMonth + i) % 12);
            }
        }

        // Sort months to ensure correct order from fiscal start month
        months.sort((a, b) => (a < startMonth ? a + 12 : a) - (b < startMonth ? b + 12 : b));

        return months;
    }

    const updateDFEntityUserAss = (obj, val) => {
        setDfEntityUserObj((prev) => { return { ...prev, [obj]: val } })
        if (obj === 'frequency') {
            if (val !== null) {
              
                // if (val === 1 || val === 6) {
                //     setFrequencyMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
                // } else if (val === 3 || val === 4 || val === 5) {
                //     setFrequencyMonths([0, 3, 6, 9])
                // } else {
                //     setFrequencyMonths([0, 2, 4, 6, 8, 10])
                // }
            }
        }
    }
    const updateDCFEntityUserAss = (obj, val) => {
        setDcfEntityUserObj((prev) => { return { ...prev, [obj]: val } })
        if (obj === 'frequency') {
            if (val !== null) {
                setFrequencyMonths(generateMonthArray(val))
                // if (val === 1 || val === 6) {
                //     setFrequencyMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
                // } else if (val === 3 || val === 4 || val === 5) {
                //     setFrequencyMonths([0, 3, 6, 9])
                // } else {
                //     setFrequencyMonths([0, 2, 4, 6, 8, 10])
                // }
            }
        }
    }
    const updateSRFEntityUserAss = (obj, val) => {
        setSrfEntityUserObj((prev) => { return { ...prev, [obj]: val } })
        if (obj === 'frequency') {
            if (val !== null) {
                setFrequencyMonths(generateMonthArray(val))
                // if (val === 1 || val === 6) {
                //     setFrequencyMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
                // } else if (val === 3 || val === 4 || val === 5) {
                //     setFrequencyMonths([0, 3, 6, 9])
                // } else {
                //     setFrequencyMonths([0, 2, 4, 6, 8, 10])
                // }
            }
        }
    }
    const AssignDCFEntity = () => {
        let loc = JSON.parse(JSON.stringify(dcfentityass))
        let ass_dcf = JSON.parse(JSON.stringify(assigneddcflist))
        let dcf_index = loc.findIndex((k) => { return k.dcfId === selecteddcfentity.dcfId })
        let ass_dcf_index = ass_dcf.findIndex((k) => { return k.id === selecteddcfentity.dcfId })

        if (dcf_index === -1 && selecteddcfentity.dcfId !== null) {


            let data = { tier0_ids: selecteddcfentity.tier0_ids, tier1_ids: selecteddcfentity.tier1_ids, tier2_ids: selecteddcfentity.tier2_ids, tier3_ids: selecteddcfentity.tier3_ids, dcfId: selecteddcfentity.dcfId, created_by: login_data.id, created_on: DateTime.utc() }
            delete data.title
            if (selecteddcfentity.comments !== undefined) {
                data.comments = selecteddcfentity.comments
            }
            APIServices.post(API.DCF_Entity_UP(admin_data.id), data).then((res) => {
                let dcfass_ = JSON.parse(JSON.stringify(dcfentityass))
                dcfass_.push({ ...res.data })
                ass_dcf[ass_dcf_index].entity = { ...res.data }
                setAssignedDcfList(ass_dcf)
                setSelectedDcfEntity({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], dcfId: null, title: null })
                setDcfEntityAss(dcfass_)
                setDcfEntityDialog(false)


            })
        } else if (dcf_index !== -1 && loc[dcf_index].id !== undefined) {
            let data = { tier0_ids: selecteddcfentity.tier0_ids, tier1_ids: selecteddcfentity.tier1_ids, tier2_ids: selecteddcfentity.tier2_ids, tier3_ids: selecteddcfentity.tier3_ids, modified_by: login_data.id, modified_on: DateTime.utc() }

            if (selecteddcfentity.comments !== undefined) {
                data.comments = selecteddcfentity.comments
            }
            APIServices.patch(API.DCF_Entity_Edit(loc[dcf_index].id), data).then((res) => {

                ass_dcf[ass_dcf_index].entity = { ...selecteddcfentity, data }
                setAssignedDcfList(ass_dcf)
                loc[dcf_index] = { ...selecteddcfentity, data }
                setDcfEntityAss(loc)
                setSelectedDcfEntity({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], dcfId: null, title: null })
                setDcfEntityDialog(false)

            })
        }

    }

    const AssignSRFEntity = () => {
        let loc = JSON.parse(JSON.stringify(srfentityass))
        let ass_dcf = JSON.parse(JSON.stringify(assignedsrflist))
        let dcf_index = loc.findIndex((k) => { return k.srfId === selectedsrfentity.srfId })
        let ass_dcf_index = ass_dcf.findIndex((k) => { return k.id === selectedsrfentity.srfId })

        if (dcf_index === -1 && selectedsrfentity.srfId !== null) {


            let data = { tier0_ids: selectedsrfentity.tier0_ids, tier1_ids: selectedsrfentity.tier1_ids, tier2_ids: selectedsrfentity.tier2_ids, tier3_ids: selectedsrfentity.tier3_ids, srfId: selectedsrfentity.srfId, created_by: login_data.id, created_on: DateTime.utc() }
            delete data.title
            if (selectedsrfentity.comments !== undefined) {
                data.comments = selectedsrfentity.comments
            }
            APIServices.post(API.SRF_Entity_UP(admin_data.id), data).then((res) => {
                let dcfass_ = JSON.parse(JSON.stringify(srfentityass))
                dcfass_.push({ ...res.data })
                ass_dcf[ass_dcf_index].entity = { ...res.data }
                setAssignedSrfList(ass_dcf)
                setSelectedSrfEntity({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], srfId: null, title: null })
                setSrfEntityAss(dcfass_)
                setSrfEntityDialog(false)


            })
        } else if (dcf_index !== -1 && loc[dcf_index].id !== undefined) {
            let data = { tier0_ids: selectedsrfentity.tier0_ids, tier1_ids: selectedsrfentity.tier1_ids, tier2_ids: selectedsrfentity.tier2_ids, tier3_ids: selectedsrfentity.tier3_ids, modified_by: login_data.id, modified_on: DateTime.utc() }
            if (selectedsrfentity.comments !== undefined) {
                data.comments = selectedsrfentity.comments
            }

            APIServices.patch(API.SRF_Entity_Edit(loc[dcf_index].id), data).then((res) => {

                ass_dcf[ass_dcf_index].entity = { ...selectedsrfentity, data }
                setAssignedSrfList(ass_dcf)
                loc[dcf_index] = { ...selectedsrfentity, data }
                setSrfEntityAss(loc)
                setSelectedSrfEntity({ tier0_ids: [], tier1_ids: [], tier2_ids: [], tier3_ids: [], srfId: null, title: null })
                setSrfEntityDialog(false)

            })
        }

    }
    const AssignDFEntity = () => {
        let loc = JSON.parse(JSON.stringify(dfentityass));
        let ass_dcf = [],
            ass_dcf_bk = [];
        if (selecteddfentity.type === 1) {
            ass_dcf = JSON.parse(JSON.stringify(requiredList));
            ass_dcf_bk = JSON.parse(JSON.stringify(requiredListBK));
        } else if (selecteddfentity.type === 2) {
            ass_dcf = JSON.parse(JSON.stringify(requiredList2));
            ass_dcf_bk = JSON.parse(JSON.stringify(requiredList2BK));
        }
        JSON.parse(JSON.stringify(requiredList));
        let dcf_index = loc.findIndex((k) => {
            return k.dfId === selecteddfentity.dfId;
        });
        let ass_dcf_index = ass_dcf.findIndex((k) => {
            return k.data1[0].rf === selecteddfentity.dfId;
        });
        let ass_dcf_index_bk = ass_dcf_bk.findIndex((k) => {
            return k.data1[0].rf === selecteddfentity.dfId;
        });
        console.log(selecteddfentity,  loc[dcf_index]);
        if (
            dcf_index === -1 && (selecteddfentity?.tier0_ids?.length || selecteddfentity?.tier1_ids?.length || selecteddfentity?.tier2_ids?.length || selecteddfentity?.tier3_ids?.length) &&
            selecteddfentity.dfId !== null &&
            selecteddfentity.consolidator_ids &&
            selecteddfentity.type !== null
        ) {
            let data = {
                consolidator_ids: selecteddfentity.consolidator_ids,
                tier0_ids: selecteddfentity.tier0_ids,
                type: selecteddfentity.type,
                tier1_ids: selecteddfentity.tier1_ids,
                tier2_ids: selecteddfentity.tier2_ids,
                tier3_ids: selecteddfentity.tier3_ids,
                dfId: selecteddfentity.dfId,
                created_by: login_data.id,
                created_on: DateTime.utc(),
            };
            delete data.title;
            if (selecteddfentity.comments !== undefined) {
                data.comments = selecteddfentity.comments;
            }
            APIServices.post(API.DF_Entity_UP(admin_data.id), data).then((res) => {
                let dcfass_ = JSON.parse(JSON.stringify(dfentityass));
                dcfass_.push({ ...res.data });
                ass_dcf[ass_dcf_index].entity = { ...res.data };
                ass_dcf_bk[ass_dcf_index_bk].entity = { ...res.data };
                if (data.type === 1) {
                    setRequiredListBK(ass_dcf_bk);
                    setRequiredList(ass_dcf);
                } else if (data.type === 2) {
                    setRequiredList2BK(ass_dcf_bk);
                    setRequiredList2(ass_dcf);
                }

                setSelectedDfEntity({
                    tier0_ids: [],
                    tier1_ids: [],
                    tier2_ids: [],
                    tier3_ids: [],
                    dfId: null,
                    title: null,
                    approver_ids: [],
                });
                setDfEntityAss(dcfass_);
                setDfEntityDialog(false);
            });
        } else if (
            dcf_index !== -1 &&
            loc[dcf_index].id !== undefined &&
            selecteddfentity.consolidator_ids &&
            selecteddfentity.type !== null
        ) {
            let data = {
                consolidator_ids: selecteddfentity.consolidator_ids,
                tier0_ids: selecteddfentity.tier0_ids,
                type: selecteddfentity.type,
                tier1_ids: selecteddfentity.tier1_ids,
                tier2_ids: selecteddfentity.tier2_ids,
                tier3_ids: selecteddfentity.tier3_ids,
                modified_by: login_data.id,
                modified_on: DateTime.utc(),
            };

            if (selecteddfentity.comments !== undefined) {
                data.comments = selecteddfentity.comments;
            }
            APIServices.patch(API.DF_Entity_Edit(loc[dcf_index].id), data).then(
                (res) => {
                    ass_dcf_bk[ass_dcf_index_bk].entity = { ...selecteddfentity, data };
                    ass_dcf[ass_dcf_index].entity = { ...selecteddfentity, data };
                    if (data.type === 1) {
                        setRequiredListBK(ass_dcf_bk);
                        setRequiredList(ass_dcf);
                    } else if (data.type === 2) {
                        setRequiredList2BK(ass_dcf_bk);
                        setRequiredList2(ass_dcf);
                    }

                    loc[dcf_index] = { ...selecteddfentity, data };
                    setDfEntityAss(loc);
                    setSelectedDfEntity({
                        tier0_ids: [],
                        tier1_ids: [],
                        tier2_ids: [],
                        tier3_ids: [],
                        dfId: null,
                        title: null,
                        consolidator_ids: [],
                    });
                    setDfEntityDialog(false);
                }
            );
        }
    };
    const AssignDFUserEntity = () => {
        setSubmitted(true);
        console.log(dfentityuserobj);
        let locdcfuserass = JSON.parse(JSON.stringify(dfentityuserass));
        let loc_index = locdcfuserass.findIndex((i) => dfentityuserobj.id === i.id);
        let ass = JSON.parse(JSON.stringify(dfentityass)).findIndex(i => dfentityuserobj.dfId === i.dfId)
        if (dfentityuserobj.id === undefined || dfentityuserobj.id === null) {
            if (
                dfentityuserobj.level !== undefined &&
                dfentityuserobj.locationId !== undefined &&
                getCoverageId(dfentityuserobj).result &&
                dfentityuserobj.level !== null &&
                dfentityuserobj.locationId !== null &&
                dfentityuserobj.frequency !== null &&
                dfentityuserobj.dfId !== undefined &&
                dfentityuserobj.dfId !== null &&
                dfentityuserobj.reporter_ids.length &&
                dfentityuserobj.start_date !== null
            ) {
                let newObj = {
                    ...getCoverageId(dfentityuserobj).data,
                    type: dfentityuserobj.type,
                    dfId: dfentityuserobj.dfId,
                    level: dfentityuserobj.level,
                    locationId: dfentityuserobj.locationId,
                    created_on: DateTime.utc(),
                    created_by: login_data.id,
                    frequency: dfentityuserobj.frequency,
                    start_date: dfentityuserobj.start_date,
                    reporter_ids: dfentityuserobj.reporter_ids,
                    entityAssId: dfentityass[ass].id,
                };

                APIServices.post(API.DF_Entity_User_UP(admin_data.id), newObj).then(
                    (res) => {
                        let locres = { ...res.data, end_date: null };
                        locdcfuserass.push(locres);
                        setDfEntityUserAss(locdcfuserass);
                        setDfEntityUserDialog(false);
                        setSubmitted(false);
                    }
                );
            }
        } else {
            if (
                dfentityuserobj.frequency !== null &&
                dfentityuserobj.dfId !== undefined &&
                dfentityuserobj.dfId !== null &&
                dfentityuserobj.reporter_ids.length &&
                dfentityuserobj.start_date !== null
            ) {
                let newObj = {
                    modified_on: DateTime.utc(),
                    modified_by: login_data.id,
                    reporter_ids: dfentityuserobj.reporter_ids,
                };
                if (dfentityuserobj.end_date !== null) {
                    newObj["end_date"] = dfentityuserobj.end_date;
                }

                APIServices.patch(
                    API.DF_Entity_User_Edit(dfentityuserobj.id),
                    newObj
                ).then((res) => {
                    locdcfuserass[loc_index] = {
                        ...locdcfuserass[loc_index],
                        id: dfentityuserobj.id,
                        ...newObj,
                    };
                    console.log(locdcfuserass);
                    setDfEntityUserAss(locdcfuserass);
                    setDfEntityUserDialog(false);
                    setSubmitted(false);
                });
            }
        }
    };
    const getCoverageId = (rowData) => {

        if (rowData.tier3_id !== undefined && rowData.tier3_id !== null) {
            return { result: true, data: { tier3_id: rowData.tier3_id } }
        } else if (rowData.tier2_id !== undefined && rowData.tier2_id !== null) {
            return { result: true, data: { tier2_id: rowData.tier2_id } }
        } else if (rowData.tier1_id !== undefined && rowData.tier1_id !== null) {
            return { result: true, data: { tier1_id: rowData.tier1_id } }
        } if (rowData.tier0_id !== undefined && rowData.tier0_id !== null) {
            return { result: true, data: { tier0_id: rowData.tier0_id } }
        } else {
            return { result: false }
        }
    }
    const AssignDCFUserEntity = () => {
        setSubmitted(true)
        console.log(dcfentityuserobj)
        let locdcfuserass = JSON.parse(JSON.stringify(dcfentityuserass))
        let loc_index = locdcfuserass.findIndex(i => dcfentityuserobj.id === i.id)
        let ass = JSON.parse(JSON.stringify(dcfentityass)).findIndex(i => dcfentityuserobj.dcfId === i.dcfId)
        console.log(ass, dcfentityass)
        if (ass !== -1) {
            if (dcfentityuserobj.id === undefined || dcfentityuserobj.id === null) {
                if (dcfentityuserobj.level !== undefined && getCoverageId(dcfentityuserobj).result && dcfentityuserobj.locationId !== undefined && dcfentityuserobj.level !== null && dcfentityuserobj.locationId !== null && dcfentityuserobj.frequency !== null && dcfentityuserobj.dcfId !== undefined && dcfentityuserobj.dcfId !== null && (stdlist.length ? dcfentityuserobj.standard !== null : true) && dcfentityuserobj.reporter_ids.length && dcfentityuserobj.start_date !== null) {
                    let newObj = { ...getCoverageId(dcfentityuserobj).data, entityAssId: dcfentityass[ass].id, dcfId: dcfentityuserobj.dcfId, level: dcfentityuserobj.level, locationId: dcfentityuserobj.locationId, created_on: DateTime.utc(), created_by: login_data.id, frequency: dcfentityuserobj.frequency, start_date: dcfentityuserobj.start_date, reporter_ids: dcfentityuserobj.reporter_ids, reviewer_ids: dcfentityuserobj.reviewer_ids, approver_ids: [] }
                    if (dcfentityuserobj.standard !== null) {
                        newObj['standard'] = dcfentityuserobj.standard
                    }

                    APIServices.post(API.DCF_Entity_User_UP(admin_data.id), newObj).then((res) => {
                        if (res.data.status) {
                            Swal.fire({
                                title: 'Assignment Added Successfully',

                                confirmButtonText: 'Exit',
                                allowOutsideClick: false,
                            })

                            locdcfuserass.push(res.data.data)
                            setDcfEntityUserAss(locdcfuserass)
                        } else {
                            if (!res.data.status && res.data.data.length) {
                                Swal.fire({
                                    title: 'Assignment Already Founded & Updated',

                                    confirmButtonText: 'Exit',
                                    allowOutsideClick: false,
                                })
                                let filteredAss = locdcfuserass.filter(i => i.dcfId === newObj.dcfId && newObj.entityAssId === i.entityAssId && newObj.tier0_id !== i.tier0_id && newObj.tier1_id !== i.tier1_id && newObj.tier2_id !== i.tier2_id && newObj.tier3_id !== i.tier3_id)
                                let afterAdded = [...filteredAss, ...res.data.data].sort((a, b) => { return a.id - b.id }, 0)
                                setDcfEntityUserAss(afterAdded)

                            } else if (!res.data.status && !res.data.data.length) {
                                Swal.fire({
                                    title: `Can't create Assignment,please reload page & try again. If issue persist inform your Platform Support Team `,

                                    confirmButtonText: 'Exit',
                                    allowOutsideClick: false,
                                })


                            }

                        }

                        setDcfEntityUserDialog(false)
                        setSubmitted(false)

                    })
                }

            } else {
                if (dcfentityuserobj.frequency !== null && dcfentityuserobj.dcfId !== undefined && dcfentityuserobj.dcfId !== null && dcfentityuserobj.reporter_ids.length && dcfentityuserobj.start_date !== null) {
                    let newObj = { modified_on: DateTime.utc(), modified_by: login_data.id, reporter_ids: dcfentityuserobj.reporter_ids, reviewer_ids: dcfentityuserobj.reviewer_ids, approver_ids: [] }
                    if (dcfentityuserobj.end_date !== null) {
                        newObj['end_date'] = dcfentityuserobj.end_date
                    }

                    APIServices.patch(API.DCF_Entity_User_Edit_Custom(dcfentityuserobj.id), newObj).then((res) => {

                        Swal.fire({
                            title: 'Updated Successfully',

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        })
                        locdcfuserass[loc_index] = { ...locdcfuserass[loc_index], id: dcfentityuserobj.id, ...newObj }
                        console.log(locdcfuserass)
                        setDcfEntityUserAss(locdcfuserass)
                        setDcfEntityUserDialog(false)
                        setSubmitted(false)

                    })
                }
            }
        }
    }
    const AssignSRFUserEntity = () => {
        setSubmitted(true)
        console.log(srfentityuserobj)
        let locdcfuserass = JSON.parse(JSON.stringify(srfentityuserass))
        let loc_index = locdcfuserass.findIndex(i => srfentityuserobj.id === i.id)

        if (srfentityuserobj.id === undefined || srfentityuserobj.id === null) {
            if (srfentityuserobj.level !== undefined && getCoverageId(srfentityuserobj).result && srfentityuserobj.locationId !== undefined && srfentityuserobj.level !== null && srfentityuserobj.locationId !== null && srfentityuserobj.frequency !== null && srfentityuserobj.srfId !== undefined && srfentityuserobj.srfId !== null && srfentityuserobj.reporter_ids.length && srfentityuserobj.start_date !== null) {
                let newObj = { ...getCoverageId(srfentityuserobj).data, srfId: srfentityuserobj.srfId, level: srfentityuserobj.level, locationId: srfentityuserobj.locationId, created_on: DateTime.utc(), created_by: login_data.id, frequency: srfentityuserobj.frequency, start_date: srfentityuserobj.start_date, reporter_ids: srfentityuserobj.reporter_ids, reviewer_ids: srfentityuserobj.reviewer_ids, approver_ids: [] }


                APIServices.post(API.SRF_Entity_User_UP(admin_data.id), newObj).then((res) => {
                    let locres = { ...res.data, end_date: null }
                    locdcfuserass.push(locres)
                    setSrfEntityUserAss(locdcfuserass)
                    setSrfEntityUserDialog(false)
                    setSubmitted(false)

                })
            }

        } else {
            if (srfentityuserobj.frequency !== null && srfentityuserobj.srfId !== undefined && srfentityuserobj.srfId !== null && srfentityuserobj.reporter_ids.length && srfentityuserobj.start_date !== null) {
                let newObj = { modified_on: DateTime.utc(), modified_by: login_data.id, reporter_ids: srfentityuserobj.reporter_ids, reviewer_ids: srfentityuserobj.reviewer_ids, approver_ids: [] }
                if (srfentityuserobj.end_date !== null) {
                    newObj['end_date'] = srfentityuserobj.end_date
                }

                APIServices.patch(API.SRF_Entity_User_Edit(srfentityuserobj.id), newObj).then((res) => {

                    locdcfuserass[loc_index] = { ...locdcfuserass[loc_index], id: srfentityuserobj.id, ...newObj }
                    console.log(locdcfuserass)
                    setSrfEntityUserAss(locdcfuserass)
                    setSrfEntityUserDialog(false)
                    setSubmitted(false)

                })
            }
        }
    }
    const dcfEntityToUserAssignment = (arr) => {
        const result = [];
        let locrfuser = JSON.parse(JSON.stringify(dcfentityuserass))
        arr.forEach(item => {
            const { dcfId, tier0_ids, tier1_ids, tier2_ids, tier3_ids } = item;
            if (tier0_ids.length > 0) {
                tier0_ids.forEach(other => {
                    let rfuserindex = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === other && i.level === 0 && i.tier0_id === other)
                    if (rfuserindex === -1) {
                        result.push({ dcfId, level: 0, locationId: other, tier0_id: other });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === other && i.level === 0 && i.tier0_id === other && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dcfId, level: 0, locationId: other, tier0_id: other });
                        }

                        result.push(...locrfuser.filter(i => i.dcfId === dcfId && i.locationId === other && i.level === 0 && i.tier0_id === other && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier1_ids.length > 0) {
                tier1_ids.forEach(country => {
                    const level = 1;
                    let rfuserindex = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === country && i.tier1_id === country && i.level === level)

                    if (rfuserindex === -1) {
                        result.push({ dcfId, level, locationId: country, tier1_id: country });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === country && i.tier1_id === country && i.level === level && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dcfId, level, locationId: country, tier1_id: country });
                        }

                        result.push(...locrfuser.filter(i => i.dcfId === dcfId && i.locationId === country && i.tier1_id === country && i.level === level && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier2_ids.length > 0) {
                tier2_ids.forEach(city => {
                    let rfuserindex = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === city && i.level === 2 && i.tier2_id === city)
                    if (rfuserindex === -1) {
                        result.push({ dcfId, level: 2, locationId: city, tier2_id: city });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === city && i.level === 2 && i.tier2_id === city && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dcfId, level: 2, locationId: city, tier2_id: city });
                        }

                        result.push(...locrfuser.filter(i => i.dcfId === dcfId && i.locationId === city && i.level === 2 && i.tier2_id === city && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier3_ids.length > 0) {
                tier3_ids.forEach(site => {
                    let rfuserindex = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === site && i.level === 3 && i.tier3_id === site)
                    if (rfuserindex === -1) {
                        result.push({ dcfId, level: 3, locationId: site, tier3_id: site });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.dcfId === dcfId && i.locationId === site && i.tier3_id === site && i.level === 3 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dcfId, level: 3, locationId: site, tier3_id: site });
                        }

                        result.push(...locrfuser.filter(i => i.dcfId === dcfId && i.locationId === site && i.tier3_id === site && i.level === 3 && i.start_date !== null && i.end_date !== null))


                    }

                });
            }
        });
        console.log(result)
        return result;
    }
    const srfEntityToUserAssignment = (arr) => {
        const result = [];
        let locrfuser = JSON.parse(JSON.stringify(srfentityuserass))
        console.log(srfentityuserass)
        arr.forEach(item => {
            const { srfId, tier0_ids, tier1_ids, tier2_ids, tier3_ids } = item;
            if (tier0_ids.length > 0) {
                tier0_ids.forEach(other => {
                    let rfuserindex = locrfuser.findIndex(i => i.srfId === srfId && i.locationId === other && i.level === 0 && i.tier0_id === other)
                    if (rfuserindex === -1) {
                        result.push({ srfId, level: 0, locationId: other, tier0_id: other });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.srfId === srfId && i.locationId === other && i.level === 0 && i.tier0_id === other && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ srfId, level: 0, locationId: other, tier0_id: other });
                        }

                        result.push(...locrfuser.filter(i => i.srfId === srfId && i.locationId === other && i.level === 0 && i.tier0_id === other && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier1_ids.length > 0) {
                tier1_ids.forEach(country => {
                    const level = 1;
                    let rfuserindex = locrfuser.findIndex(i => i.srfId === srfId && i.tier1_id === country && i.locationId === country && i.level === level)

                    if (rfuserindex === -1) {
                        result.push({ srfId, level, locationId: country, tier1_id: country });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.srfId === srfId && i.tier1_id === country && i.locationId === country && i.level === level && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ srfId, level, locationId: country, tier1_id: country });
                        }

                        result.push(...locrfuser.filter(i => i.srfId === srfId && i.tier1_id === country && i.locationId === country && i.level === level && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier2_ids.length > 0) {
                tier2_ids.forEach(city => {
                    let rfuserindex = locrfuser.findIndex(i => i.srfId === srfId && i.tier2_id === city && i.locationId === city && i.level === 2)
                    if (rfuserindex === -1) {
                        result.push({ srfId, level: 2, locationId: city, tier2_id: city });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.srfId === srfId && i.tier2_id === city && i.locationId === city && i.level === 2 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ srfId, level: 2, locationId: city, tier2_id: city });
                        }

                        result.push(...locrfuser.filter(i => i.srfId === srfId && i.tier2_id === city && i.locationId === city && i.level === 2 && i.start_date !== null && i.end_date !== null))

                    }

                });
            }
            if (tier3_ids.length > 0) {
                tier3_ids.forEach(site => {
                    let rfuserindex = locrfuser.findIndex(i => i.srfId === srfId && i.tier3_id === site && i.locationId === site && i.level === 3)
                    if (rfuserindex === -1) {
                        result.push({ srfId, level: 3, locationId: site, tier3_id: site });
                    } else {
                        let rfuserindex2 = locrfuser.findIndex(i => i.srfId === srfId && i.tier3_id === site && i.locationId === site && i.level === 3 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {

                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ srfId, level: 3, locationId: site, tier3_id: site });
                        }

                        result.push(...locrfuser.filter(i => i.srfId === srfId && i.tier3_id === site && i.locationId === site && i.level === 3 && i.start_date !== null && i.end_date !== null))


                    }

                });
            }
        });
        console.log(result)
        return result;
    }
    const dfEntityToUserAssignment = (arr) => {
        const result = [];
        let locrfuser = JSON.parse(JSON.stringify(dfentityuserass))
        arr.forEach(item => {
            const { dfId, tier0_ids, tier1_ids, tier2_ids, tier3_ids } = item;
            const type = requiredList.findIndex(i => i.data1[0].rf === dfId) === -1 ? 2 : 1
            if (tier0_ids.length > 0) {
                tier0_ids.forEach(other => {
                    let rfuserindex = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === other && i.tier0_id === other && i.level === 0)
                    if (rfuserindex === -1) {
                        result.push({ dfId, level: 0, locationId: other, type, tier0_id: other });
                    } else {


                        let rfuserindex2 = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === other && i.tier0_id === other && i.level === 0 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dfId, level: 0, locationId: other, type, tier0_id: other });
                        }

                        result.push(...locrfuser.filter(i => i.dfId === dfId && i.locationId === other && i.tier0_id === other && i.level === 0 && i.start_date !== null && i.end_date !== null))



                    }

                });
            }
            if (tier1_ids.length > 0) {
                tier1_ids.forEach(country => {
                    const level = 1;
                    let rfuserindex = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === country && i.tier1_id === country && i.level === level)

                    if (rfuserindex === -1) {
                        result.push({ dfId, level, locationId: country, type, tier1_id: country });
                    } else {

                        let rfuserindex2 = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === country && i.tier1_id === country && i.level === 1 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dfId, level: 1, locationId: country, type, tier1_id: country });
                        }

                        result.push(...locrfuser.filter(i => i.dfId === dfId && i.locationId === country && i.tier1_id === country && i.level === 1 && i.start_date !== null && i.end_date !== null))



                    }

                });
            }
            if (tier2_ids.length > 0) {
                tier2_ids.forEach(city => {
                    let rfuserindex = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === city && i.tier2_id === city && i.level === 2)
                    if (rfuserindex === -1) {
                        result.push({ dfId, level: 2, locationId: city, type, tier2_id: city });
                    } else {


                        let rfuserindex2 = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === city && i.tier2_id === city && i.level === 2 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dfId, level: 2, locationId: city, type, tier2_id: city });
                        }

                        result.push(...locrfuser.filter(i => i.dfId === dfId && i.locationId === city && i.tier2_id === city && i.level === 2 && i.start_date !== null && i.end_date !== null))




                    }

                });
            }
            if (tier3_ids.length > 0) {
                tier3_ids.forEach(site => {
                    let rfuserindex = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === site && i.tier3_id === site && i.level === 3)
                    if (rfuserindex === -1) {
                        result.push({ dfId, level: 3, locationId: site, type, tier3_id: site });
                    } else {



                        let rfuserindex2 = locrfuser.findIndex(i => i.dfId === dfId && i.locationId === site && i.tier3_id === site && i.level === 3 && i.start_date !== null && i.end_date === null)
                        if (rfuserindex2 !== -1) {
                            console.log(locrfuser[rfuserindex2])
                            result.push(locrfuser[rfuserindex2]);
                        } else {
                            result.push({ dfId, level: 3, locationId: site, type, tier3_id: site });
                        }

                        result.push(...locrfuser.filter(i => i.dfId === dfId && i.locationId === site && i.tier3_id === site && i.level === 3 && i.start_date !== null && i.end_date !== null))


                    }

                });
            }
        });
        console.log(result)
        return result;
    }
    const getDcf = (rf) => {
        console.log(rf)
        let loc = assigneddcflist.findIndex((i) => { return i.id === rf.dcfId })
        if (loc !== -1) {
            return { ...assigneddcflist[loc], ...rf, assId: rf.id }
        } else {
            return null
        }
    }
    const getRf = (rf) => {
        console.log(rf)
        let loc1 = requiredList.findIndex((i) => { return i.data1[0].rf === rf.dfId })
        let loc2 = requiredList2.findIndex((i) => { return i.data1[0].rf === rf.dfId })
        let loc1_bk = requiredListBK.findIndex((i) => { return i.data1[0].rf === rf.dfId })
        let loc2_bk = requiredList2BK.findIndex((i) => { return i.data1[0].rf === rf.dfId })
        if (loc1 !== -1 || loc2 !== -1) {
            if (loc1 !== -1 && loc2 !== -1) {
                return null
            } else if (loc1 !== -1) {
                console.log(requiredListBK[loc1_bk])

                // requiredListBK[loc1_bk] = { ...requiredListBK[loc1_bk], ...rf, assId: rf.id }
                return { ...requiredList[loc1], ...rf, assId: rf.id }


            } else if (loc2 !== -1) {
                // requiredList2BK[loc2_bk] = { ...requiredList2BK[loc2_bk], ...rf, assId: rf.id }
                return { ...requiredList2[loc2], ...rf, assId: rf.id }
            }


        } else {
            return null
        }
    }
    const getSrf = (rf) => {
        console.log(rf)
        let loc = assignedsrflist.findIndex((i) => { return i.id === rf.srfId })
        if (loc !== -1) {
            return { ...assignedsrflist[loc], ...rf, assId: rf.id }
        } else {
            return null
        }
    }
    const renderTable = (val) => {
        console.log(val)
        setSelectedFramework(val)
        let filtered = []
        if (val.length) {
            filtered = requiredListBK.filter(obj => {
                return obj.overallTags.some(tagsArray => {
                    return tagsArray.some(tag => {
                        return val.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                    });
                });
            });
        } else {
            filtered = requiredListBK
        }
        console.log(filtered.length)
        // let filtered = requiredListBK.filter((i) => { return (i.overallTags.some(array => array.some(item => item.includes(val))) || val === 'All') })
        setRequiredList(filtered)
    }
    const renderTable2 = (val) => {
        setSelectedFramework2(val)
        let filtered = []
        if (val.length) {
            filtered = requiredList2BK.filter(obj => {
                return obj.overallTags.some(tagsArray => {
                    return tagsArray.some(tag => {
                        return val.some(searchTerm => tag.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()));
                    });
                });
            });
        } else {
            filtered = requiredList2BK
        }
        // let filtered = requiredList2BK.filter((i) => { return (i.overallTags.some(array => array.some(item => item.includes(val))) || val === 'All') })
        setRequiredList2(filtered)
    }
    function getFiscalYearsFromStartDate(start_date) {
        const startDate = DateTime.fromISO(start_date, { zone: "Asia/Calcutta" })
        const currentDate = DateTime.local();

        let startFiscalYear, currentFiscalYear;
        const fiscalYears = [];

        if (fymonth === 1) {
            // When fiscal month is January, fiscal year matches the calendar year
            startFiscalYear = startDate.year;
            currentFiscalYear = currentDate.year;

            // Add fiscal years only up to the current or next year if already in January
            for (let year = startFiscalYear; year <= currentFiscalYear; year++) {
                fiscalYears.push({ name: year, label: `${year}` });
            }
        } else {
            // Normal fiscal year spanning two calendar years
            startFiscalYear = startDate.month >= fymonth ? startDate.year : startDate.year - 1;
            currentFiscalYear = currentDate.month >= fymonth ? currentDate.year : currentDate.year - 1;

            // Add fiscal years from the starting fiscal year to the current fiscal year + 1
            for (let year = startFiscalYear; year <= currentFiscalYear + 1; year++) {
                const label = `${year}-${(year + 1).toString().slice(-2)}`;
                fiscalYears.push({ name: year + 1, label });
            }

            // Exclude future fiscal years if the current month is before the fiscal start month
            if (currentDate.month < fymonth) {
                fiscalYears.pop();
            }
        }

        return fiscalYears;
    }

    const updateSummary = (yr) => {
        let loc = [...JSON.parse(JSON.stringify(dcfentityuserass.filter(i => assigneddcflist.map(i => i.id).includes(i.dcfId))))]
        console.log(yr)
        if (yr !== 0) {
            let act = filterAssignmentsByFiscalYear(loc, yr)
            act.forEach(i => {
                i.ent = getCoverageText(i).name
                if (i.dcfId) {
                    i.formtype = 1
                    let index = assigneddcflist.findIndex(x => x.id === i.dcfId)
                    console.log(assigneddcflist)
                    if (index !== -1) {
                        i.form = assigneddcflist[index].title

                    } else {
                        i.form = 'Not Found'
                    }

                } else if (i.dfId) {
                    let index = dflist.findIndex(x => x.id === i.dfId)
                    i.formtype = 2
                    console.log(dflist)
                    if (index !== -1) {
                        i.form = dflist[index].title

                    } else {
                        i.form = 'Not Found'
                    }

                } else if (i.srfId) {
                    let index = assignedsrflist.findIndex(x => x.id === i.srfId)
                    i.formtype = 3
                    if (index !== -1) {
                        i.form = assignedsrflist[index].title

                    } else {
                        i.form = 'Not Found'
                    }

                }
            })
            setSummaryData(act)
        } else {
            loc.forEach(i => {
                i.ent = getCoverageText(i).name
                if (i.dcfId) {
                    i.formtype = 1
                    let index = assigneddcflist.findIndex(x => x.id === i.dcfId)
                    console.log(assigneddcflist)
                    if (index !== -1) {
                        i.form = assigneddcflist[index].title

                    } else {
                        i.form = 'Not Found'
                    }

                } else if (i.dfId) {
                    let index = dflist.findIndex(x => x.id === i.dfId)
                    i.formtype = 2
                    console.log(dflist)
                    if (index !== -1) {
                        i.form = dflist[index].title

                    } else {
                        i.form = 'Not Found'
                    }

                } else if (i.srfId) {
                    let index = assignedsrflist.findIndex(x => x.id === i.srfId)
                    i.formtype = 3
                    if (index !== -1) {
                        i.form = assignedsrflist[index].title

                    } else {
                        i.form = 'Not Found'
                    }

                }
            })
            setSummaryData(loc)
        }
        setSummaryYear(yr)
        forceUpdate()
    }
    const getFiscalYearRange = (year, fymonth) => {
        let startDate, endDate;
        console.log(fymonth)

        if (fymonth === 1) {
            startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: 12, day: 31 }).endOf('day');
        } else {
            startDate = DateTime.fromObject({ year: year - 1, month: fymonth, day: 1 }).startOf('day');
            endDate = DateTime.fromObject({ year, month: fymonth - 1, day: 1 }).endOf('month');
        }

        return { startDate, endDate };
    };
    function getFiscalYearMonths(year, fymonth) {
        let months = [];
        const startYear = fymonth === 1 ? year : year - 1;

        for (let i = 0; i < 12; i++) {
            // Calculate the month number (1-12)
            let month = (fymonth + i - 1) % 12 + 1;

            // Calculate the correct year based on the fiscal month
            let currentYear = month >= fymonth ? startYear : startYear + 1;

            // Format the month as 'MM-YYYY'
            months.push(`${month.toString().padStart(2, '0')}-${currentYear}`);
        }

        return months;
    }
    // Function to filter assignments
    const filterAssignmentsByFiscalYear = (assignments, year) => {
        const { startDate, endDate } = getFiscalYearRange(year, fymonth);
        const currentDate = DateTime.local().startOf('day');

        return assignments.filter(assignment => {
            const assignmentStartDate = assignment.start_date ? DateTime.fromISO(assignment.start_date).startOf('day') : currentDate;
            const assignmentEndDate = assignment.end_date ? DateTime.fromISO(assignment.end_date).startOf('day') : currentDate;

            return (assignmentStartDate >= startDate && assignmentStartDate <= endDate) ||
                (assignmentEndDate >= startDate && assignmentEndDate <= endDate);
        });
    };
    return (
        <>
            {(admin_data.id === 17 || admin_data.id === 289 || admin_data.id === 28) ?
                <>
                    <div style={{
                        fontSize: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: '600', marginBottom: 10
                    }} >Data Assignment </div>

                    {/* <ReadMoreComponent  percent={65} content={`<div>This screen is designed to help you manage data assignments effectively. It includes several tabs, such as <b>Quantitative Assignment</b>, which requires measurable data inputs like fuel consumption, <b>Qualitative (Required)</b> and <b>Qualitative (Optional)</b> for mandatory and optional qualitative data, respectively. <b>Supplier Form Assignment</b> for supplier-specific data; and Summary, which provides a overview of all assignments. <br/> The Description column lists the specific data points or activities to be reported, while the <b>Reporting Entities</b> column indicates the number of entities responsible for providing data. You can click <b>Add More</b> to assign additional reporting entities. The <b>Assign Responsibilities</b> column tracks progress by showing completed and pending assignments, with red indicating incomplete and green indicating completed tasks </div> ` } />  */}
                    <div>This screen is designed to help you manage data assignments effectively. It includes several tabs, such as <b>Quantitative Assignment</b>, which requires measurable data inputs like fuel consumption, <b>Qualitative (Required)</b> and <b>Qualitative (Optional)</b> for mandatory and optional qualitative data, respectively. <b>Supplier Form Assignment</b> for supplier-specific data; and Summary, which provides a overview of all assignments. <br /> The Description column lists the specific data points or activities to be reported, while the <b>Reporting Entities</b> column indicates the number of entities responsible for providing data. You can click <b>Add More</b> to assign additional reporting entities. The <b>Assign Responsibilities</b> column tracks progress by showing completed and pending assignments, with red indicating incomplete and green indicating completed tasks </div>

                    <TabView activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index); if (e.index === 3) { setSummaryDatas() } }} panelContainerStyle={{ height: 'calc(100vh - 200px)' }}>
                        <TabPanel header={"Quantitative Assignment" + (!load ? `(${assigneddcflist.length})` : '')} >
                            <DataTable filters={{ section: { matchMode: 'in', value: null }, id: { matchMode: 'in', value: null } }} loading={load} className="user-ass-tab-1" value={assigneddcflist} key={'id'} scrollable paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='DCF Not Found'>
                                <Column header='Description' body={dcfTitleTemplate} field='id' filter showFilterMatchModes={false} filterElement={dcfIdRowFilter} />
                                <Column header='DCF ID' field='id' />
                                <Column alignHeader={'center'} header='Section' field='section' filter showFilterMatchModes={false} filterElement={sectionRowFilter} body={sectionTemplate} />
                                <Column alignHeader={'center'} header='Reporting Entities' body={addQNEntityTemplate} />
                                <Column alignHeader={'center'} header='Assign Responsibilities' body={viewDcfEntityTemplate} />
                            </DataTable>
                        </TabPanel>

                        <TabPanel header={"Qualitative " + (!load ? `(${requiredList.length})` : '')} >
                            <div>
                                <div style={{ marginBottom: 10 }}>
                                    <label
                                        style={{
                                            marginRight: 10,
                                        }}
                                    >
                                        Filter by Standards / Frameworks / Disclosures
                                    </label>
                                    <MultiSelect display="chip" style={{ width: 300 }} value={selectedFramework} onChange={(e) => renderTable(e.value)} options={assFramework} optionLabel="title" optionValue="title"
                                        filter={true} placeholder="Select" panelClassName={'hidefilter'} />

                                </div>
                            </div>
                            <DataTable className="user-ass-tab-3" loading={load} filters={{ section: { matchMode: 'in', value: null } }} value={requiredList} scrollable paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} >
                                <Column field='cat_title' header='Category' />
                                <Column field='top_title' header='Topic' />
                                <Column field='title' body={titleTemplate} header='Aspect' />
                                <Column alignHeader={'center'} header='Section' field='section' filter showApplyButton={true} showFilterMatchModes={false} filterElement={sectionRowFilter} body={sectionTemplate} />
                                <Column header='Requirement' body={rrTemplate} />
                                <Column
                                    alignHeader={"center"} style={{ width: 200, padding: 5 }}
                                    header="Reporting Entities"
                                    body={addQLEntityTemplate}
                                />
                                <Column
                                    alignHeader={"center"} style={{ width: 200, padding: 5 }}
                                    header="Assign Responsibilities"
                                    body={viewDfEntityTemplate}
                                />
                            </DataTable>
                        </TabPanel>
                        <TabPanel
                            header={
                                "Supplier Form Assignment" +
                                (!load ? `(${assignedsrflist.length})` : "")
                            }
                        >
                            <DataTable
                                className="user-ass-tab-4"
                                loading={load}
                                value={assignedsrflist}
                                key={"id"}
                                scrollable
                                paginator
                                rows={10}
                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                currentPageReportTemplate="({currentPage} of {totalPages})"
                                emptyMessage="SRF Not Found"
                                rowsPerPageOptions={[10, 20, 50, 100]}
                            >
                                <Column header="Description" field="title" style={{ padding: 5 }} body={srfTitleTemplate} />
                                <Column header='SRF ID' field='id' />
                                <Column
                                    alignHeader={"center"} style={{ width: 200, padding: 5 }}
                                    header="Reporting Entities"
                                    body={addSRFEntityTemplate}
                                />

                                <Column
                                    alignHeader={"center"} style={{ width: 200, padding: 5 }}
                                    header="Assign Responsibilities"
                                    body={viewSrfEntityTemplate}
                                />
                            </DataTable>
                        </TabPanel>
                        <TabPanel header={"DCF Assignment Summary" + (!load ? `(${getSummaryCount()})` : '')}  >
                            <div>
                                <div className="col-12">
                                    <div className="col-12 row flex align-items-center">
                                        <div className="col-2"> Select Year </div>
                                        <div className="col-2">
                                            <Dropdown options={[{ label: 'All', name: 0 }, ...yearoptions]} value={summaryyear} optionLabel="label" optionValue="name" style={{ width: '100%' }} onChange={(e) => { updateSummary(e.value) }} />

                                        </div>
                                        <div className="col-8 flex justify-content-end" > <Button rounded onClick={downloadAssignemntReport} label="Export Report" /> </div>

                                    </div>
                                </div>
                            </div>
                            <DataTable className="user-ass-tab-5" ref={sumdtref} loading={load} filters={overallfilter} value={summarydata} key={'id'} scrollable paginator rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})" rowsPerPageOptions={[10, 20, 50, 100]} emptyMessage='Assignment Not Found'>
                                <Column header="Type" field='formtype' showFilterMatchModes={false} filter showApplyButton={true} filterElement={typeRowFilterTemplate} body={formTypeTemplate} />
                                <Column header="Form" field='form' showFilterMatchModes={false} filter showApplyButton={true} filterElement={formRowFilterTemplate} body={formTemplate} />
                                <Column header="Entity" field='ent' showFilterMatchModes={false} filter showApplyButton={true} filterElement={entityRowFilterTemplate} body={coverageTemplate_} />
                                <Column alignHeader={'center'} header="Frequency" showFilterMatchModes={false} filter body={frequencyTemplate} showApplyButton={true} filterElement={frequencyRowFilterTemplate} field='frequency' />
                                <Column alignHeader={'center'} header="Start Date" field='start_date' body={startDateTemplate} />
                                <Column alignHeader={'center'} header="End Date" field='end_date' body={endDateTemplate} />
                                <Column alignHeader={'center'} header="Reporter" field='reporter_ids' body={reporterListTemplate} />
                                <Column alignHeader={'center'} header="Reviewer" field='reviewer_ids' body={reviewerListTemplate} />

                                {/* <Column alignHeader={'center'} header="View" body={allActionBodyTemplate} /> */}
                            </DataTable>

                        </TabPanel>
                    </TabView>
                    <Dialog
                        visible={dcfentitydialog}
                        style={{
                            width: "60%",
                        }}
                        header={`Assign entities to submit Quantitative Information on " ` + selecteddcfentity.title + ` "`}
                        modal
                        className="p-fluid"

                        onHide={() => { setDcfEntityDialog(false) }}
                    >
                        <div>
                            <div className="col-12">
                                <ReadMoreComponent percent={65} content={`<div>The “Assign Entities” popup tab which appears on a click of “<b>Add More</b>” allows you to assign entities to submit data based on the data collection hierarchy, including <b>Corporate</b>, <b>${label1}</b>, <b>${label2}</b> and <b>${label3}</b> levels. Start by toggling the <b>Corporate</b> option if assigning at the corporate level. Use the <b>${label1}</b>, <b>${label2}</b> and <b>${label3}</b> sections to select relevant locations by checking the boxes or using the search bar for quick navigation. To select all entities in a section, click the box next to the empty search bar after making your selection. Selected entities will appear as tags for easy review. At the bottom, the <b>Corporate</b> summary provides an overview of your selections by <b>${label1}</b>, <b>${label2}</b> and <b>${label3}</b>. Ensure your selections are accurate before saving changes. If a location is missing, check the higher-level filters to confirm the hierarchy.
<br/> In the <b>justification</b> box, provide a clear rationale for not selecting the specified entities to collect this data, detailing their relevance, operational scale, and availability. Additionally, explain why data is not being collected from certain entities, as this information will be essential for internal and external assurance processes.</div> ` } />

                                <div className="col-12 ">
                                    <div className='col-12 '>
                                        <div className="flex align-items-center">
                                            <InputSwitch inputId="corporate" checked={selecteddcfentity.tier0_ids.includes(0)} onChange={(e) => { updateDCFEntity('tier0_ids', e.value ? [0] : []) }} />

                                            <label htmlFor="corporate" className="ml-2">Corporate</label>
                                        </div>
                                    </div>
                                    <div className='col-12  align-items-center justify-content-between'>
                                        <div className="col-12">
                                            <label className="fs-16 fw-5">{label1}</label>
                                        </div>
                                        <div className="col-12">

                                            <MultiSelect filter={true} display={'chip'} className="w-full " panelClassName={'hidefilter'} optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selecteddcfentity.tier1_ids} options={selectedloclist.tier1} onChange={(e) => { updateDCFEntity('tier1_ids', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-12  align-items-center justify-content-between'>
                                        <div className="col-12">
                                            <label className="fs-16 fw-5">{label2}</label>
                                        </div>
                                        <div className="col-12">
                                            <MultiSelect filter={true} display={'chip'} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selecteddcfentity.tier2_ids} options={selectedloclist.tier2} onChange={(e) => { updateDCFEntity('tier2_ids', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-12  align-items-center justify-content-between'>
                                        <div className="col-12">
                                            <label className="fs-16 fw-5">{label3}</label>
                                        </div>
                                        <div className="col-12">
                                            <MultiSelect filter={true} display={'chip'} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selecteddcfentity.tier3_ids} options={selectedloclist.tier3} onChange={(e) => { updateDCFEntity('tier3_ids', e.value) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12" style={{ padding: 15 }}>
                                    <div className="col-12" style={{ background: '#F9F9F9F2' }}>

                                        {selecteddcfentity.tier0_ids.includes(0) && <div className='col-12 fw-6 fs-16'> Corporate <hr /> </div>}
                                        <div className="col-12 grid m-0">
                                            {getEntityOptions(selecteddcfentity).map((item) => {
                                                return (<div className="col-4"  >
                                                    <div className='col-12 fw-6 fs-16'> {item.label} ({item.items.length}) </div>
                                                    {item.items.map((item2) => {
                                                        return <div class='col-12'>{item2.name}</div>
                                                    })

                                                    }
                                                </div >
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" style={{ padding: 15 }}>
                                <label className="col-12">Justification for selection of the above entities and for  non-assignment (if applicable) </label>

                                <div className="  flex justify-content-center" style={{ padding: 10 }}>
                                    <InputTextarea style={{ height: 150, overflow: 'auto', resize: 'none' }} value={selecteddcfentity.comments} autoResize={false} onChange={(e) => { updateDCFEntity('comments', e.target.value) }} />
                                </div>


                            </div>
                            <div className="flex justify-content-end">
                                <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignDCFEntity() }}>Assign Entity</Button>
                            </div>

                        </div>
                    </Dialog>
                    <Dialog
                        visible={dcfentityviewdialog}
                        style={{
                            width: "75%",
                        }}
                        header={`Assign Entity User for " ` + selecteddcfentity.title + ` "`}
                        modal
                        className="p-fluid"

                        onHide={() => { setDcfEntityViewDialog(false) }}
                    >
                        <div>
                            {/* <ReadMoreComponent  percent={65} content={`<div>You are enabled to assign users to entities for data collection and review based on the selected entities. The table lists entities, such as countries or regions, with fields for <b>Frequency</b>, <b>Start Date</b>, <b>End Date</b>, <b>Reporter</b>, and <b>Reviewer</b>. To assign roles, click the <b>ASSIGN</b> button next to an entity which leads to the popup tab to fill in the details of the mentioned fields. </div>` } />  */}
                            <div>You are enabled to assign users to entities for data collection and review based on the selected entities. The table lists entities, such as countries or regions, with fields for <b>Frequency</b>, <b>Start Date</b>, <b>End Date</b>, <b>Reporter</b>, and <b>Reviewer</b>. To assign roles, click the <b>ASSIGN</b> button next to an entity which leads to the popup tab to fill in the details of the mentioned fields.</div>
                            <DataTable value={dcfEntityToUserAssignment([selecteddcfentity]).map((i) => { return getDcf(i) }).filter(i => i !== null)} scrollable rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})"  >

                                <Column header="Entity" field='locationId' body={coverageTemplate} />
                                <Column alignHeader={'center'} header="Frequency" field='frequency' body={frequencyTemplate} />
                                <Column alignHeader={'center'} header="Start Date" field='start_date' body={startDateTemplate} />
                                <Column alignHeader={'center'} header="End Date" field='end_date' body={endDateTemplate} />
                                <Column alignHeader={'center'} header="Reporter" field='reporter_ids' body={reporterListTemplate} />
                                <Column alignHeader={'center'} header="Reviewer" field='reviewer_ids' body={reviewerListTemplate} />

                                <Column alignHeader={'center'} header="Action" body={dcfActionBodyTemplate} />
                            </DataTable>

                        </div>
                    </Dialog>
                    <Dialog
                        visible={srfentityviewdialog}
                        style={{
                            width: "75%",
                        }}
                        header={`Assign Entity User for " ` + selectedsrfentity.title + ` "`}
                        modal
                        className="p-fluid"

                        onHide={() => { setSrfEntityViewDialog(false) }}
                    >
                        <div>
                            <DataTable value={srfEntityToUserAssignment([selectedsrfentity]).map((i) => { return getSrf(i) }).filter(i => i !== null)} scrollable rows={10} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="({currentPage} of {totalPages})"  >

                                <Column header="Entity" field='locationId' body={coverageTemplate} />
                                <Column alignHeader={'center'} header="Frequency" field='frequency' body={frequencyTemplate} />
                                <Column alignHeader={'center'} header="Start Date" field='start_date' body={startDateTemplate} />
                                <Column alignHeader={'center'} header="End Date" field='end_date' body={endDateTemplate} />
                                <Column alignHeader={'center'} header="Reporter" field='reporter_ids' body={reporterListTemplate} />
                                <Column alignHeader={'center'} header="Reviewer" field='reviewer_ids' body={reviewerListTemplate} />

                                <Column alignHeader={'center'} header="Action" body={srfActionBodyTemplate} />
                            </DataTable>

                        </div>
                    </Dialog>

                    <Dialog
                        visible={dfenitityviewdialog}
                        style={{
                            width: "60%",
                        }}
                        header={`Assign Entity User for " ` + selecteddfentity.title + ` "`}
                        modal
                        className="p-fluid"
                        onHide={() => {
                            setDfEnitityViewDialog(false);
                        }}
                    >
                        <div>
                            <DataTable
                                value={dfEntityToUserAssignment([selecteddfentity])
                                    .map((i) => {
                                        return getRf(i);
                                    })
                                    .filter((i) => i !== null)}
                                scrollable
                                rows={10}
                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                currentPageReportTemplate="({currentPage} of {totalPages})"
                            >
                                <Column
                                    header="Entity"
                                    field="locationId"
                                    body={coverageTemplate}
                                />
                                <Column
                                    alignHeader={"center"}
                                    header="Frequency"
                                    field="frequency"
                                    body={frequencyTemplate}
                                />
                                <Column
                                    alignHeader={"center"}
                                    header="Start Date"
                                    field="start_date"
                                    body={startDateTemplate}
                                />
                                <Column
                                    alignHeader={"center"}
                                    header="End Date"
                                    field="end_date"
                                    body={endDateTemplate}
                                />
                                <Column
                                    alignHeader={"center"}
                                    header="Reporter"
                                    field="reporter_ids"
                                    body={reporterListTemplate}
                                />

                                <Column
                                    alignHeader={"center"}
                                    header="Action"
                                    body={dfActionBodyTemplate}
                                />
                            </DataTable>
                        </div>
                    </Dialog>
                    <Dialog
                        visible={dfentitydialog}
                        style={{
                            width: "60%",
                        }}
                        header={
                            `Assign entities to submit Qualitative Information on " ` +
                            selecteddfentity.title +
                            ` "`
                        }
                        modal
                        className="p-fluid"
                        onHide={() => {
                            setDfEntityDialog(false);
                        }}
                    >
                        <div>
                            <div className="col-12">
                                <div className="col-12 ">
                                    <div className='col-12 '>
                                        <div className="flex align-items-center">

                                            <InputSwitch inputId="corporate" checked={selecteddfentity.tier0_ids.includes(0)} onChange={(e) => { updateDFEntity('tier0_ids', e.value ? [0] : []) }} />

                                            <label htmlFor="corporate" className="ml-2">Corporate</label>
                                        </div>
                                    </div>
                                    <div className='col-12  align-items-center justify-content-between'>
                                        <div className="col-12">
                                            <label className="fs-16 fw-5">{label1}</label>
                                        </div>
                                        <div className="col-12">

                                            <MultiSelect filter={true} display={'chip'} className="w-full " panelClassName={'hidefilter'} optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selecteddfentity.tier1_ids} options={selectedloclist.tier1} onChange={(e) => { updateDFEntity('tier1_ids', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-12  align-items-center justify-content-between'>
                                        <div className="col-12">
                                            <label className="fs-16 fw-5">{label2}</label>
                                        </div>
                                        <div className="col-12">
                                            <MultiSelect filter={true} display={'chip'} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selecteddfentity.tier2_ids} options={selectedloclist.tier2} onChange={(e) => { updateDFEntity('tier2_ids', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-12  align-items-center justify-content-between'>
                                        <div className="col-12">
                                            <label className="fs-16 fw-5">{label3}</label>
                                        </div>
                                        <div className="col-12">
                                            <MultiSelect filter={true} display={'chip'} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selecteddfentity.tier3_ids} options={selectedloclist.tier3} onChange={(e) => { updateDFEntity('tier3_ids', e.value) }} />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 grid m-0"
                                >
                                    <div className="col-5">
                                        <label
                                            style={{
                                                margin: 10,
                                            }}
                                        >
                                            Assign Consolidator
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <MultiSelect
                                            display="chip"
                                            value={selecteddfentity.consolidator_ids}
                                            onChange={(e) => updateDFEntity("consolidator_ids", e.value)}
                                            options={approverList}
                                            optionLabel="name"
                                            optionValue="id"
                                            filter={true}
                                            placeholder="Select Consolidator(s)"
                                            className={
                                                "w-full " +
                                                (selecteddfentity.consolidator_ids === null ||
                                                    selecteddfentity.consolidator_ids && selecteddfentity.consolidator_ids.length === 0
                                                    ? "p-invalid"
                                                    : "")
                                            }
                                            panelClassName={"hidefilter"}
                                        />
                                    </div>
                                </div>
                                <div className="col-12" style={{ padding: 15 }}>
                                    <div className="col-12" style={{ background: '#F9F9F9F2' }}>

                                        {selecteddfentity.tier0_ids.includes(0) && <div className='col-12 fw-6 fs-16'> Corporate <hr /> </div>}
                                        <div className="col-12 grid m-0">
                                            {getEntityOptions(selecteddfentity).map((item) => {
                                                return (<div className="col-4"  >
                                                    <div className='col-12 fw-6 fs-16'> {item.label} ({item.items.length}) </div>
                                                    {item.items.map((item2) => {
                                                        return <div class='col-12'>{item2.name}</div>
                                                    })

                                                    }
                                                </div >
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" style={{ padding: 15 }}>
                                <label className="col-12">Justification for selection of the above entities and for  non-assignment (if applicable) </label>

                                <div className="  flex justify-content-center" style={{ padding: 10 }}>
                                    <InputTextarea style={{ height: 150, overflow: 'auto', resize: 'none' }} value={selecteddfentity.comments} autoResize={false} onChange={(e) => { updateDFEntity('comments', e.target.value) }} />
                                </div>


                            </div>
                            <div className="flex justify-content-end">
                                <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignDFEntity() }}>Assign Entity</Button>
                            </div>

                        </div>
                    </Dialog>
                    <Dialog
                        visible={srfentitydialog}
                        style={{
                            width: "75%",
                        }}
                        header={`Assign entities to submit Supplier Information on " ` + selectedsrfentity.title + ` "`}
                        modal
                        className="p-fluid"

                        onHide={() => { setSrfEntityDialog(false) }}
                    >
                        <div>
                            <div className="col-12 grid">
                                <div className="col-6 ">
                                    <div className='col-12 grid'>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="corporate" name="corporate" onChange={(e) => { updateSRFEntity('tier0_ids', e.checked ? [0] : []) }} checked={selectedsrfentity.tier0_ids.includes(0)} />
                                            <label htmlFor="corporate" className="ml-2">Corporate</label>
                                        </div>
                                    </div>
                                    <div className='col-12 grid align-items-center justify-content-between'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">Tier1</label>
                                        </div>
                                        <div className="col-8">

                                            <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" optionValue="id" style={{ width: '100%' }} value={selectedsrfentity.tier1_ids} options={selectedloclist.tier1} onChange={(e) => { updateSRFEntity('tier1_ids', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-12 grid align-items-center justify-content-between'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">Tier2</label>
                                        </div>
                                        <div className="col-8">
                                            <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selectedsrfentity.tier2_ids} options={selectedloclist.tier2} onChange={(e) => { updateSRFEntity('tier2_ids', e.value) }} />
                                        </div>
                                    </div>
                                    <div className='col-12 grid align-items-center justify-content-between'>
                                        <div className="col-3">
                                            <label className="fs-16 fw-5">Tier3</label>
                                        </div>
                                        <div className="col-8">
                                            <MultiSelect filter={true} className="w-full " panelClassName={'hidefilter'} optionLabel="name" multiple optionValue="id" style={{ width: '100%' }} value={selectedsrfentity.tier3_ids} options={selectedloclist.tier3} onChange={(e) => { updateSRFEntity('tier3_ids', e.value) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <ListBox options={getEntityOptions(selectedsrfentity)} optionLabel="name"
                                        optionGroupLabel="name" optionGroupChildren="items" listClassName="dis-option-hover" itemTemplate={itemTemplate} optionGroupTemplate={groupEntityTemplate} className="w-full" listStyle={{ height: '250px' }} />

                                </div>
                            </div>
                            <div className="col-12" style={{ padding: 15 }}>
                                <label className="col-12">Justification for selection of the above entities and for  non-assignment (if applicable) </label>

                                <div className="  flex justify-content-center" style={{ padding: 10 }}>
                                    <InputTextarea style={{ height: 150, overflow: 'auto', resize: 'none' }} value={selectedsrfentity.comments} autoResize={false} onChange={(e) => { updateSRFEntity('comments', e.target.value) }} />
                                </div>


                            </div>
                            <div className="flex justify-content-end">
                                <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignSRFEntity() }}>Assign Entity</Button>
                            </div>

                        </div>
                    </Dialog>

                    <Dialog
                        visible={dcfentityuserdialog}
                        style={{
                            width: "75%",
                        }}
                        header={"Assign User for " + selecteddcfentity.title + " to '" + getCoverageText(dcfentityuserobj).name + "'"}

                        modal
                        className="p-fluid"

                        onHide={() => { setDcfEntityUserDialog(false) }}
                    >
                        <div>
                            {/* <ReadMoreComponent  percent={65} content={`<div>In the popup tab, select the <b>Frequency</b> (e.g., Monthly, Quarterly) to determine how often data will be collected, and set the <b>Start Date</b> to define when data collection begins. Use the <b>Assign Reporter</b> dropdown to choose a user with the "Data Reporter" role responsible for providing data and the <b>Assign Reviewer</b> dropdown to assign someone with the "Data Reviewer" role to review it. If the same user is both a reporter and reviewer, leave the reviewer field unchanged. Once all fields are completed, click <b>Assign User</b> to save the assignment. Repeat this process for all listed entities, ensuring the information is accurate. Use the dropdown menus for quick user selection and verify all details before saving.</div>` } />  */}
                            <div>In the popup tab, select the <b>Frequency</b> (e.g., Monthly, Quarterly) to determine how often data will be collected, and set the <b>Start Date</b> to define when data collection begins. Use the <b>Assign Reporter</b> dropdown to choose a user with the "Data Reporter" role responsible for providing data and the <b>Assign Reviewer</b> dropdown to assign someone with the "Data Reviewer" role to review it. If the same user is both a reporter and reviewer, leave the reviewer field unchanged. Once all fields are completed, click <b>Assign User</b> to save the assignment. Repeat this process for all listed entities, ensuring the information is accurate. Use the dropdown menus for quick user selection and verify all details before saving.</div>

                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        Set Data Frequency
                                    </label>
                                </div>
                                <div className="col-7 ddf">
                                    <Dropdown disabled={dcfentityuserobj.id !== undefined} style={{ width: '100%', height: '100%' }} value={dcfentityuserobj.frequency} optionValue="id" options={frequency_list} onChange={(e) => updateDCFEntityUserAss("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                                </div>



                            </div>
                            {submitted && (dcfentityuserobj.frequency === null) &&
                                (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red", marginLeft: '22%'
                                        }}
                                    >

                                        Select Frequency
                                    </small>
                                )}
                            {stdlist.length !== 0 &&
                                <>
                                    <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div className="col-5">
                                            <label
                                                style={{
                                                    margin: 10,
                                                }}
                                            >
                                                Select Standard
                                            </label>
                                        </div>
                                        <div className="col-7 ddf">
                                            <Dropdown disabled={dcfentityuserobj.id !== undefined} showClear style={{ width: '100%' }} optionValue={'id'} value={dcfentityuserobj.standard} options={stdlist} onChange={(e) => { updateDCFEntityUserAss("standard", e.value) }} optionLabel="title" placeholder="Select Standard" />
                                        </div>


                                    </div>
                                    {submitted && (dcfentityuserobj.standard === null) &&
                                        (
                                            <small
                                                className="p-invalid"
                                                style={{
                                                    color: "red", marginLeft: '22%'
                                                }}
                                            >

                                                Select Standard
                                            </small>
                                        )}
                                </>}

                            {dcfentityuserobj.frequency !== null && <>
                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div className="col-5">
                                        <label
                                            style={{
                                                margin: 10,
                                            }}
                                        >
                                            Start Date
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <ReactDatePicker
                                            minDate={minDate === null ? entryStartDate : moment(minDate).add(1, 'month').toDate()}
                                            maxDate={moment(minDate).add(1, 'month').toDate()}
                                            disabled={dcfentityuserobj.id !== undefined && dcfentityuserobj.start_date !== null}
                                            selected={dcfentityuserobj.start_date}
                                            onSelect={(e) => { updateDCFEntityUserAss("start_date", e) }}
                                            renderMonthContent={renderMonthContent}
                                            showMonthYearPicker
                                            dateFormat="MM/yyyy"
                                        />


                                    </div>



                                </div>
                                {submitted && (dcfentityuserobj.start_date === null) &&
                                    (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red", marginLeft: '22%'
                                            }}
                                        >

                                            set start date
                                        </small>
                                    )}
                            </>}
                            {dcfentityuserobj.id !== undefined && dcfentityuserobj.start_date !== null && dcfentityuserobj.disabled !== true && <>
                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div className="col-5">
                                        <label
                                            style={{
                                                margin: 10,
                                            }}
                                        >
                                            End Date
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <ReactDatePicker
                                            disabled={((dcfentityuserobj.disable_end !== undefined))}
                                            selected={dcfentityuserobj.end_date}
                                            onSelect={(e) => { updateDCFEntityUserAss("end_date", e) }}
                                            minDate={moment(dcfentityuserobj.start_date).toDate()}
                                            showMonthYearPicker
                                            renderMonthContent={(a,b,c) => {return renderEndMonthContent(a,b,c,dcfentityuserobj.frequency)}}
                                            maxDate={DateTime.utc().toJSDate()}
                                            dateFormat="MM/yyyy"
                                        />


                                    </div>



                                </div>

                            </>}
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10
                                        }}
                                    >
                                        Assign Reporter
                                    </label>
                                </div>
                                <div className="col-7">
                                    <MultiSelect display="chip" value={dcfentityuserobj.reporter_ids} onChange={(e) => updateDCFEntityUserAss("reporter_ids", e.value)} options={userList} optionLabel="name" optionValue="id"
                                        filter={true} placeholder="Select Reporter" className="w-full " panelClassName={'hidefilter'} />

                                </div>



                            </div>
                            {submitted && (dcfentityuserobj.reporter_ids === null || dcfentityuserobj.reporter_ids.length === 0) &&
                                (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red", marginLeft: '22%'
                                        }}
                                    >

                                        Select Atleast One Reporter
                                    </small>
                                )}

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10
                                        }}
                                    >
                                        Assign Reviewer
                                    </label>
                                </div>
                                <div className="col-7">
                                    <MultiSelect display="chip" value={dcfentityuserobj.reviewer_ids} onChange={(e) => updateDCFEntityUserAss("reviewer_ids", e.value)} options={reviewerList} optionLabel="name" optionValue="id"
                                        filter={true} placeholder="Leave this as-is in case reporter is also the reviewer" className="w-full " panelClassName={'hidefilter'} />

                                </div>



                            </div>




                            <div className="flex justify-content-end">
                                <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignDCFUserEntity() }}>Assign User </Button>
                            </div>

                        </div>
                    </Dialog>
                    <Dialog
                        visible={dfentityuserdialog}
                        style={{
                            width: "60%",
                        }}
                        header={"Assign User for " + selecteddfentity.title + " to '" + getCoverageText(dfentityuserobj).name + "'"}
                        modal
                        className="p-fluid"
                        onHide={() => {
                            setDfEntityUserDialog(false);
                        }}
                    >
                        <div>
                            {/* <div
                                style={{
                                    marginBottom: 20,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        Set Data Frequency
                                    </label>
                                </div>
                                <div className="col-7 ddf">
                                    <Dropdown
                                        disabled={dfentityuserobj.id !== undefined}
                                        style={{ width: "100%", height: "100%" }}
                                        value={dfentityuserobj.frequency}
                                        optionValue="id"
                                        options={frequency_list.slice(2)}
                                        onChange={(e) => updateDFEntityUserAss("frequency", e.value)}
                                        optionLabel="name"
                                        placeholder="Select frequency"
                                    />
                                </div>
                            </div>
                            {submitted && dfentityuserobj.frequency === null && (
                                <small
                                    className="p-invalid"
                                    style={{
                                        color: "red",
                                        marginLeft: "22%",
                                    }}
                                >
                                    Select Frequency
                                </small>
                            )} */}

                            {dfentityuserobj.frequency !== null && (
                                <>
                                    <div
                                        style={{
                                            marginBottom: 20,
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div className="col-5">
                                            <label
                                                style={{
                                                    margin: 10,
                                                }}
                                            >
                                                Start Date
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <ReactDatePicker

                                                minDate={
                                                    minDate === null
                                                        ? entryStartDate
                                                        : moment(minDate).add(1, "month").toDate()
                                                }
                                                maxDate={moment(minDate).add(1, "month").toDate()}
                                                disabled={
                                                    dfentityuserobj.id !== undefined &&
                                                    dfentityuserobj.start_date !== null
                                                }
                                                selected={dfentityuserobj.start_date}
                                                onSelect={(e) => {
                                                    updateDFEntityUserAss("start_date", e);
                                                }}
                                                renderMonthContent={renderMonthContent}
                                                showMonthYearPicker
                                                dateFormat="MM/yyyy"
                                            />
                                        </div>
                                    </div>
                                    {submitted && dfentityuserobj.start_date === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                                marginLeft: "22%",
                                            }}
                                        >
                                            set start date
                                        </small>
                                    )}
                                </>
                            )}
                            {dfentityuserobj.id !== undefined &&
                                dfentityuserobj.start_date !== null &&
                                dfentityuserobj.disabled !== true && (
                                    <>
                                        <div
                                            style={{
                                                marginBottom: 20,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div className="col-5">
                                                <label
                                                    style={{
                                                        margin: 10,
                                                    }}
                                                >
                                                    End Date
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <ReactDatePicker
                                                    disabled={dfentityuserobj.disable_end !== undefined}
                                                    selected={dfentityuserobj.end_date}
                                                    onSelect={(e) => {
                                                        updateDFEntityUserAss("end_date", e);
                                                    }}
                                                    minDate={moment(dfentityuserobj.start_date).toDate()}
                                                    showMonthYearPicker
                                                    renderMonthContent={(a,b,c) => {return renderEndMonthContent(a,b,c,dfentityuserobj.frequency)}}
                                                    maxDate={DateTime.utc().toJSDate()}
                                                    dateFormat="MM/yyyy"
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        Assign Reporter
                                    </label>
                                </div>
                                <div className="col-7">
                                    <MultiSelect
                                        display="chip"
                                        value={dfentityuserobj.reporter_ids}
                                        onChange={(e) => updateDFEntityUserAss("reporter_ids", e.value)}
                                        options={userList}
                                        optionLabel="name"
                                        optionValue="id"
                                        filter={true}
                                        placeholder="Select Reporter"
                                        className="w-full "
                                        panelClassName={"hidefilter"}
                                    />
                                </div>
                            </div>
                            {submitted &&
                                (dfentityuserobj.reporter_ids === null ||
                                    dfentityuserobj.reporter_ids.length === 0) && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                            marginLeft: "22%",
                                        }}
                                    >
                                        Select Atleast One Reporter
                                    </small>
                                )}

                            <div className="flex justify-content-end">
                                <Button
                                    style={{ marginTop: 20, width: "auto" }}
                                    onClick={() => {
                                        AssignDFUserEntity();
                                    }}
                                >
                                    Assign User{" "}
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog
                        visible={srfentityuserdialog}
                        style={{
                            width: "75%",
                        }}
                        header={"Assign User for " + selectedsrfentity.title + " to '" + getCoverageText(srfentityuserobj).name + "'"}

                        modal
                        className="p-fluid"

                        onHide={() => { setSrfEntityUserDialog(false) }}
                    >
                        <div>

                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        Set Data Frequency
                                    </label>
                                </div>
                                <div className="col-7 ddf">
                                    <Dropdown disabled={srfentityuserobj.id !== undefined} style={{ width: '100%', height: '100%' }} value={srfentityuserobj.frequency} optionValue="id" options={frequency_list} onChange={(e) => updateSRFEntityUserAss("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                                </div>



                            </div>
                            {submitted && (srfentityuserobj.frequency === null) &&
                                (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red", marginLeft: '22%'
                                        }}
                                    >

                                        Select Frequency
                                    </small>
                                )}

                            {srfentityuserobj.frequency !== null && <>
                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div className="col-5">
                                        <label
                                            style={{
                                                margin: 10,
                                            }}
                                        >
                                            Start Date
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <ReactDatePicker
                                            minDate={minDate === null ? entryStartDate : moment(minDate).add(1, 'month').toDate()}
                                            maxDate={moment(minDate).add(1, 'month').toDate()}
                                            disabled={srfentityuserobj.id !== undefined && srfentityuserobj.start_date !== null}
                                            selected={srfentityuserobj.start_date}
                                            onSelect={(e) => { updateSRFEntityUserAss("start_date", e) }}
                                            renderMonthContent={renderMonthContent}
                                            showMonthYearPicker
                                            dateFormat="MM/yyyy"
                                        />


                                    </div>



                                </div>
                                {submitted && (srfentityuserobj.start_date === null) &&
                                    (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red", marginLeft: '22%'
                                            }}
                                        >

                                            set start date
                                        </small>
                                    )}
                            </>}
                            {srfentityuserobj.id !== undefined && srfentityuserobj.start_date !== null && srfentityuserobj.disabled !== true && <>
                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div className="col-5">
                                        <label
                                            style={{
                                                margin: 10,
                                            }}
                                        >
                                            End Date
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <ReactDatePicker
                                            disabled={((srfentityuserobj.disable_end !== undefined))}
                                            selected={srfentityuserobj.end_date}
                                            onSelect={(e) => { updateSRFEntityUserAss("end_date", e) }}
                                            minDate={moment(srfentityuserobj.start_date).toDate()}
                                            showMonthYearPicker
                                            renderMonthContent={(a,b,c) => {return renderEndMonthContent(a,b,c,srfentityuserobj.frequency)}}
                                            maxDate={DateTime.utc().toJSDate()}
                                            dateFormat="MM/yyyy"
                                        />


                                    </div>



                                </div>

                            </>}
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10
                                        }}
                                    >
                                        Assign Reporter
                                    </label>
                                </div>
                                <div className="col-7">
                                    <MultiSelect display="chip" value={srfentityuserobj.reporter_ids} onChange={(e) => updateSRFEntityUserAss("reporter_ids", e.value)} options={supplierList} optionLabel="name" optionValue="id"
                                        filter={true} placeholder="Select Reporter" className="w-full " panelClassName={'hidefilter'} />

                                </div>



                            </div>
                            {submitted && (srfentityuserobj.reporter_ids === null || srfentityuserobj.reporter_ids.length === 0) &&
                                (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red", marginLeft: '22%'
                                        }}
                                    >

                                        Select Atleast One Reporter
                                    </small>
                                )}

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10
                                        }}
                                    >
                                        Assign Reviewer
                                    </label>
                                </div>
                                <div className="col-7">
                                    <MultiSelect display="chip" value={srfentityuserobj.reviewer_ids} onChange={(e) => updateSRFEntityUserAss("reviewer_ids", e.value)} options={reviewerList} optionLabel="name" optionValue="id"
                                        filter={true} placeholder="Leave this as-is in case reporter is also the reviewer" className="w-full " panelClassName={'hidefilter'} />

                                </div>



                            </div>





                            <div className="flex justify-content-end">
                                <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { AssignSRFUserEntity() }}>Assign User </Button>
                            </div>

                        </div>
                    </Dialog>
                    <Dialog
                        visible={allentityuserdialog}
                        style={{
                            width: "60%",
                        }}
                        header={"Viewing Entity -  " + allentityuserobj.ent}
                        modal
                        className="p-fluid"

                        onHide={() => { setAllEntityUserDialog(false) }}
                    >
                        <div>
                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        Type
                                    </label>
                                </div>
                                <div className="col-7 ">

                                    <label>{allentityuserobj.dcfId ? 'Quantitative' : allentityuserobj.dfId ? 'Qualitative' : allentityuserobj.srfId ? 'Supplier' : 'NA'}</label>
                                </div>



                            </div>
                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        Form
                                    </label>
                                </div>
                                <div className="col-7 ">

                                    <label>{allentityuserobj.form}</label>
                                </div>



                            </div>
                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10,
                                        }}
                                    >
                                        Assigned Data Frequency
                                    </label>
                                </div>
                                <div className="col-7 ddf">
                                    <Dropdown disabled={true} style={{ width: '100%', height: '100%' }} value={allentityuserobj.frequency} optionValue="id" options={frequency_list} onChange={(e) => updateDCFEntityUserAss("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                                </div>



                            </div>
                            {submitted && (allentityuserobj.frequency === null) &&
                                (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red", marginLeft: '22%'
                                        }}
                                    >

                                        Select Frequency
                                    </small>
                                )}

                            {allentityuserobj.frequency !== null && <>
                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div className="col-5">
                                        <label
                                            style={{
                                                margin: 10,
                                            }}
                                        >
                                            Start Date
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <ReactDatePicker
                                            minDate={minDate === null ? entryStartDate : moment(minDate).add(1, 'month').toDate()}
                                            maxDate={moment(minDate).add(1, 'month').toDate()}
                                            disabled={true}
                                            selected={allentityuserobj.start_date}
                                            onSelect={(e) => { updateDCFEntityUserAss("start_date", e) }}
                                            renderMonthContent={renderMonthContent}
                                            showMonthYearPicker
                                            dateFormat="MM/yyyy"
                                        />


                                    </div>



                                </div>
                                {submitted && (allentityuserobj.start_date === null) &&
                                    (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red", marginLeft: '22%'
                                            }}
                                        >

                                            set start date
                                        </small>
                                    )}
                            </>}
                            {allentityuserobj.id !== undefined && allentityuserobj.end_date !== null && <>
                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div className="col-5">
                                        <label
                                            style={{
                                                margin: 10,
                                            }}
                                        >
                                            End Date
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <ReactDatePicker
                                            disabled={true}
                                            selected={allentityuserobj.end_date}
                                            onSelect={(e) => { updateDCFEntityUserAss("end_date", e) }}
                                            minDate={moment(allentityuserobj.start_date).toDate()}
                                            showMonthYearPicker
                                            renderMonthContent={(a,b,c) => {return renderEndMonthContent(a,b,c,allentityuserobj.frequency)}}
                                            maxDate={DateTime.utc().toJSDate()}
                                            dateFormat="MM/yyyy"
                                        />


                                    </div>



                                </div>

                            </>}
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10
                                        }}
                                    >
                                        Reporter(s) Assigned
                                    </label>
                                </div>
                                <div className="col-7">

                                    <MultiSelect display="chip" value={allentityuserobj.reporter_ids} selectAll={false} options={allentityuserobj.srfId !== undefined ? supplierList.filter(i => allentityuserobj.reporter_ids.includes(i.id)) : userList.filter(i => allentityuserobj.reporter_ids.includes(i.id))} optionLabel="name" optionValue="id"
                                        filter={true} placeholder="Select Reporter" className="w-full " panelClassName={'hidefilter'} />

                                </div>



                            </div>
                            {submitted && (allentityuserobj.reporter_ids === null || allentityuserobj.reporter_ids.length === 0) &&
                                (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red", marginLeft: '22%'
                                        }}
                                    >

                                        Select Atleast One Reporter
                                    </small>
                                )}

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div className="col-5">
                                    <label
                                        style={{
                                            margin: 10
                                        }}
                                    >
                                        Reviewer(s) Assigned
                                    </label>
                                </div>
                                <div className="col-7">
                                    {allentityuserobj.reviewer_ids && allentityuserobj.reviewer_ids.length ?
                                        <MultiSelect display="chip" value={allentityuserobj.reviewer_ids} selectAll={false} options={reviewerList.filter(i => allentityuserobj.reviewer_ids.includes(i.id))} optionLabel="name" optionValue="id"
                                            filter={true} placeholder="Leave this as-is in case reporter is also the reviewer" className="w-full " panelClassName={'hidefilter'} />
                                        :
                                        <label>Self Review</label>
                                    }

                                </div>



                            </div>





                            <div className="flex justify-content-end">
                                <Button style={{ marginTop: 20, width: 'auto' }} onClick={() => { setAllEntityUserDialog(false) }}>Close</Button>
                            </div>

                        </div>
                    </Dialog>
                    <Dialog
                        visible={historydialog}
                        style={{
                            width: "30%",
                        }}
                        header={"Response History"}
                        modal
                        className="p-fluid"

                        onHide={() => { setHistoryDialog(false) }}
                    >
                        <div>
                            {historydata.sort((a, b) => { return moment(b.last_modified_on).toDate() - moment(a.last_modified_on).toDate() }).map((i) => {
                                return (
                                    <div style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', padding: 5, borderRadius: 10, margin: 5, boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px' }}>
                                        <text style={{ color: 'black', borderRadius: 10, padding: 5, fontStyle: 'italic', cursor: 'pointer' }} onClick={() => { window.open(window.location.origin + '/df_submission_preview/').sessionStorage.setItem('dfpreview', JSON.stringify({ id: i.dfId, params: { state: { data: activeRowData, oldData: i } } })) }}>{DateTime.fromISO(i.last_modified_on, { zone: 'utc' }).toLocal().toFormat('dd-MMM-yyyy HH:mm')} Hours by <span style={{ color: 'green' }}>{i.submitted_by === admin_data.id ? 'Admin' : userList_.findIndex((j) => { return j.id === i.submitted_by }) === -1 ? 'User Deleted' : userList_.find((j) => { return j.id === i.submitted_by }).information.empname}</span> </text>

                                    </div>
                                )
                            })

                            }
                        </div>
                    </Dialog>
                </> :
                <div className='flex justify-content-center'> Cant View other Client Screen </div>}
        </>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TVSAssignDcfToUser, comparisonFn);