import React, { useEffect, useState, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import { Button } from 'primereact/button';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';
import { OrderList } from 'primereact/orderlist';
import useForceUpdate from 'use-force-update';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';

import moment from 'moment';
import axios from 'axios';

import { AutoComplete as AC } from 'primereact/autocomplete';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import readXlsxFile from 'read-excel-file'

import { CascadeSelect } from 'primereact/cascadeselect';

import * as XLSX from 'xlsx'
import FileSaver from 'file-saver';
import { setRef } from '@fullcalendar/core';
import { SplitButton } from 'primereact/splitbutton';
import Swal from 'sweetalert2';


import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"

import cx from "classnames";

import { useSelector } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';
import $ from 'jquery'
import { API } from '../../../../constants/api_url';
import APIServices from '../../../../service/APIService';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { DateTime } from 'luxon';
const google = window.google;
// @ts-ignore
window.jQuery = $;
// @ts-ignore
window.$ = $;
// (0.79/1000)

const AddClientDealerTVS = () => {

    const [data, setData] = useState([])
    const [allimporteddata, setAllImportedData] = useState([])
    const [apidata, setAPiData] = useState([])
    const allRoles = useSelector((state) => state.user.allRoles)
    const [filter2, setFilter2] = useState('')
    const [dealer, setDealer] = useState({ zonalManager: null, pinCode: '', dealerType: null, assessmentStartMonth: null, coords: { lat: null, lng: null }, principalContact: null, principalName: '', salesmContact: null, salesmName: '', workersmContact: null, workersmName: '', zonalOffice: null, servicecenterType: null, showroomType: null, dealerContact: null, dealerName: '', email: '', dealerCategory: null, dealerLocation: '', dealerCode: '' })
    const categoryList = [{ name: 'Two Wheeler', value: 1 }]
    const dealerType = [{ name: 'Authorized Main Dealer', value: 1 }, { name: 'Authorized Dealer', value: 2 }, { name: 'Area Office', value: 3 }, { name: 'Authorized Parts Stockist (APS)', value: 4 }]

    const zonalOfficeList = [{ name: "East", value: 1 }, { name: "Central", value: 2 }, { name: "North", value: 3 }, { name: "South 1", value: 4 }, { name: "South 2", value: 5 }, { name: "West 1", value: 6 }, , { name: "West 2", value: 7 }]
    const propertyTypeList = [{ name: 'Own property', value: 1 }, { name: 'Rented property', value: 2 }]

    const [adddealerdialog, setAddDealerDialog] = useState(false)

    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)

    const [submitted, setSubmitted] = useState(false)

    const forceUpdate = useForceUpdate()
    const dt = useRef(null);




    useEffect(() => {

        renderData()

        let uriString = {
            "include": ["locationTwos"]

        }


    }, [])

    const renderData = () => {
        let datas = []
        let promise = new Promise((resolve, error) => {
            APIServices.get(API.UserProfile)
                .then((res) => {
                    setAPiData(res.data)

                    res.data.forEach((item, index) => {
                        if (item.role === 'clientdealer' && item.clientId === admin_data.id) {

                            datas.push({
                                id: item.id, dealerName: item.information.dealerName || '', dealerType: item?.information?.dealerType || null, assessmentStartMonth: item?.information?.assessmentStartMonth || null, dealerCategory: item.information.dealerCategory ? item.information.dealerCategory : null, dealerLocation: item.information.dealerLocation || '', dealerCode: item.dealerCode || '', empmailid: item.email, information: item, role: item?.role, dealerContact: item?.information?.dealerContact || '', salesmContact: item?.information?.salesmContact || null, workersmContact: item?.information?.workersmContact || null, principalContact: item?.information?.principalContact || null, principalName: item?.information?.principalName || ''
                                , workersmName: item?.information?.workersmName || '', salesmName: item?.information?.salesmName || '', pinCode: item?.information?.pinCode || null, coords: item?.information?.coords || { lat: null, lng: null }, zonalManager: item?.information?.zonalManager || null,
                                showroomType: item?.information?.showroomType || null, servicecenterType: item?.information?.servicecenterType || null, zonalOffice: item?.information?.zonalOffice || null,

                            })
                        }
                        if (index === res.data.length - 1) {
                            resolve(datas)
                        }
                    })
                })
        })
        promise.then((d) => { console.log(d); setData(d); forceUpdate() })


    }
    const updateDealer = (obj, val) => {
        let loc = dealer
        loc[obj] = val
        setDealer(loc)
        forceUpdate()
    }
    const editDealer = (rowData) => {
        console.log(rowData)
        if (rowData.assessmentStartMonth) {
            rowData.assessmentStartMonth = DateTime.fromISO(rowData.assessmentStartMonth, { zone: "utc" }).toJSDate()
        }
        setDealer(rowData)
        setAddDealerDialog(true)
    }
    const dealerCategoryTemplate = (rowData) => {

        return (
            <div >

                {categoryList.find(x => x.id === rowData.category)?.name || 'NA'}
            </div>
        );
    }
    const triggerMonthTemplate = (rowData) => {

        return (
            <div >

                {rowData.assessmentStartMonth ? DateTime.fromISO(rowData.assessmentStartMonth, { zone: "Asia/Calcutta" }).toFormat('LLL-yyyy') : 'Not Set'}
            </div>
        );
    }
    const dealerTypeTemplate = (rowData) => {

        return (
            <div >

                {dealerType.find(x => x.id === rowData.dealerType)?.name || 'NA'}
            </div>
        );
    }
    const dealerNameTemplate = (rowData) => {

        return (
            <div className='cur-pointer text-underline' onClick={() => { editDealer(rowData) }}>

                {rowData.dealerName}
            </div>
        );
    }
    const emailTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                <span className="p-column-title">Approved</span>
                {rowData.empmailid}
            </>
        );
    }
    const addNewDealer = () => {

        setSubmitted(true)
        console.log(dealer)
        if (dealer.zonalManager && dealer.showroomType && dealer.servicecenterType && dealer.pinCode && dealer.dealerName.trim().length !== 0 && dealer.dealerCategory !== null && dealer.dealerType && dealer.dealerCode.trim().length) {

            let newObj = { information: {} }
            newObj['dealerCode'] = dealer.dealerCode
            newObj.information['dealerCategory'] = dealer.dealerCategory
            newObj.information['dealerType'] = dealer.dealerType
            newObj.information['assessmentStartMonth'] = dealer.assessmentStartMonth
            newObj.information['dealerName'] = dealer.dealerName
            newObj.information['dealerLocation'] = dealer.dealerLocation
            newObj.information['dealerContact'] = dealer.dealerContact
            newObj.information['pinCode'] = dealer.pinCode
            newObj.information['principalContact'] = dealer.principalContact
            newObj.information['principalName'] = dealer.principalName
            newObj.information['salesmContact'] = dealer.salesmContact
            newObj.information['salesmName'] = dealer.salesmName
            newObj.information['workersmContact'] = dealer.workersmContact
            newObj.information['workersmName'] = dealer.workersmName
            newObj.information['zonalOffice'] = dealer.zonalOffice
            newObj.information['showroomType'] = dealer.showroomType
            newObj.information['servicecenterType'] = dealer.servicecenterType
            newObj.information['zonalManager'] = dealer.zonalManager
            newObj.information['placeId'] = dealer.placeId



            console.log(newObj)
            APIServices.patch(API.UserProfile_Edit(dealer.id), newObj)
                .then((res) => {
                    setDealer({ zonalManager: null, pinCode: '', dealerType: null, assessmentStartMonth: null, coords: { lat: null, lng: null }, principalContact: null, principalName: '', salesmContact: null, salesmName: '', workersmContact: null, workersmName: '', zonalOffice: null, servicecenterType: null, showroomType: null, dealerContact: null, dealerName: '', email: '', dealerCategory: null, dealerLocation: '', dealerCode: '' })

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `Updated successfully`,
                        showConfirmButton: false,
                        timer: 1500
                    })

                    renderData()
                }).catch((e) => {
                    setDealer({ zonalManager: null, pinCode: '', dealerType: null, assessmentStartMonth: null, coords: { lat: null, lng: null }, principalContact: null, principalName: '', salesmContact: null, salesmName: '', workersmContact: null, workersmName: '', zonalOffice: null, servicecenterType: null, showroomType: null, dealerContact: null, dealerName: '', email: '', dealerCategory: null, dealerLocation: '', dealerCode: '' })

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: `Something went wrong`,
                        showConfirmButton: false,
                        timer: 1500
                    })

                })
            setAddDealerDialog(false)

            setSubmitted(false)

        }

    }
    const updateLocationData = async () => {
        let pincode = document.getElementById('pincode').value

        if (pincode) {
            var geocoder = new google.maps.Geocoder();


            try {

                geocoder.geocode({ address: pincode }, function (results, status) {
                    if (status === "OK") {
                        // Successfully retrieved geocoding data
                        console.log("Geocoding Results:", results);

                        const location = results[0].geometry.location;

                        // Get latitude and longitude
                        const latitude = location.lat();
                        const longitude = location.lng();
                        let loc = dealer
                        loc.pinCode = pincode
                        loc['placeId'] = results[0].place_id
                        loc.coords = { lng: longitude, lat: latitude }
                        setDealer(loc)
                        forceUpdate()

                    } else {
                        console.error("Geocoding failed due to:", status);
                        let loc = dealer
                        loc.pinCode = ''
                        loc.coords = { lng: null, lat: null }
                        loc['placeId'] = undefined
                        setDealer(loc)
                        forceUpdate()
                    }
                });
            } catch (error) {
                console.error('Error during Geocoding Request:', error.message);
                let loc = dealer
                loc.pinCode = ''
                loc.coords = { lng: null, lat: null }
                loc['placeId'] = undefined
                setDealer(loc)
                forceUpdate()
                return null;
            }
        }
    }
    const header2 = (
        <div className="flex justify-content-end ">

            <span className="block mt-2 md:mt-0 p-input-icon-left" style={{ width: '30%' }}>
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => { setFilter2(e.target.value) }} placeholder="Search..." />
            </span>
        </div>
    );
    const addDealerDialogFooter = () => {
        return (<div>
            <Button label="Submit" icon="pi pi-check" className="p-button-text" onClick={() => { addNewDealer() }} />

        </div>)
    }
    return (
        <div className="grid p-fluid">
            {(login_data.role === 'clientadmin' || allRoles.includes(12) || allRoles.includes(2)) ? <div className="col-12 md:col-12">
                <div className="card ">
                    <div className="col-12 md:col-12" style={{

                        textAlign: 'center',
                        textDecorationColor: 'green'
                    }}>
                        <h5> {`Dealer (${data.length})`} </h5>
                    </div>

                    <div>

                        <DataTable ref={dt} value={data.filter(i => i.role === 'clientdealer')}
                            scrollable globalFilterFields={['dealerName']} globalFilter={filter2}
                            dataKey="id" paginator rows={20} rowsPerPageOptions={[10, 20, 50, 100]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                            emptyMessage="No records found." header={header2} responsiveLayout="scroll">
                            {/* <Column field="config" header="Role Config" body={configTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }}></Column> */}
                            <Column field="empname" header="Name" body={dealerNameTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="empmailid" header="Email" body={emailTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column field="dealerContact" header="Contact" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column header="Category" headerStyle={{ width: '14%', minWidth: '10rem' }} body={dealerCategoryTemplate}></Column>
                            <Column header="Type" headerStyle={{ width: '14%', minWidth: '10rem' }} body={dealerTypeTemplate}></Column>
                            <Column header="Self Assessment Month" headerStyle={{ width: '14%', minWidth: '10rem' }} body={triggerMonthTemplate}></Column>


                        </DataTable>
                    </div>







                </div>
            </div> :
                <div className='card col-12'>
                    You have no rights to access this page
                </div>
            }
            <Dialog visible={adddealerdialog} style={{ width: '450px' }} header="Edit Dealer" modal className="p-fluid" footer={addDealerDialogFooter} onHide={() => { setSubmitted(false); setAddDealerDialog(false) }}>

                <div className="field">
                    <label htmlFor="refdate">Dealer Name <span style={{ color: 'red' }}>*</span></label>
                    <InputText type={'text'} disabled value={dealer.dealerName} onChange={(e) => { updateDealer('dealerName', e.target.value) }} placeholder="Name" />

                </div>
                <div className="field">
                    <label htmlFor="refdate">Dealer ID <span style={{ color: 'red' }}>*</span></label>
                    <InputText value={dealer.dealerCode} onChange={(e) => { updateDealer('dealerCode', e.target.value) }} placeholder="Dealer Code" />
                    {submitted && dealer.dealerCode.trim().length === 0 && <small className="p-invalid" style={{ color: 'red' }}>Dealer Id is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">Dealer E-Mail ID / Login ID <span style={{ color: 'red' }}>*</span></label>
                    <InputText disabled={true} type={'email'} value={dealer.empmailid} placeholder="Mail ID" />


                </div>
                <div className="field">
                    <label htmlFor="refdate">Dealer Contact No</label>
                    <InputText keyfilter={'pnum'} value={dealer.dealerContact} onChange={(e) => { updateDealer('dealerContact', e.target.value) }} placeholder="Contact No" />
                </div>
                <div className="field">
                    <label htmlFor="refdate">Category <span style={{ color: 'red' }}>*</span></label>
                    <Dropdown value={dealer.dealerCategory} optionLabel='name' onChange={(e) => { updateDealer('dealerCategory', e.target.value) }} placeholder="Category" options={categoryList} />
                    {submitted && dealer.dealerCategory === null && <small className="p-invalid" style={{ color: 'red' }}>Dealer Category is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">Category <span style={{ color: 'red' }}>*</span></label>
                    <Dropdown value={dealer.dealerType} optionLabel='name' onChange={(e) => { updateDealer('dealerType', e.target.value) }} placeholder="Type" options={dealerType} />
                    {submitted && dealer.dealerType === null && <small className="p-invalid" style={{ color: 'red' }}>Dealer Type is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">Trigger Self Assessment Start Month </label>
                    <Calendar view={'month'} dateFormat={'M-yy'} value={dealer.assessmentStartMonth} onChange={(e) => { updateDealer('assessmentStartMonth', e.value) }} placeholder="Start Month" />
                </div>
                <div className="field">
                    <label htmlFor="refdate">Postal Code {dealer.pinCode ? <i style={{ color: 'green' }} className='ml-2 pi pi-check fs-14' /> : <i style={{ color: 'red' }} className='ml-2 pi pi-times fs-14' />} </label>
                    <div className="p-inputgroup flex-1">
                        <InputNumber inputId='pincode' useGrouping={false} placeholder="postal/pin/zip code" value={dealer.pinCode} />
                        <Button icon="pi pi-search" style={{ color: 'white' }} className="clr-navy" onClick={() => { updateLocationData(dealer) }} />
                    </div>
                </div>

                <div className="field">
                    <label htmlFor="refdate">Location </label>
                    <InputText type={'text'} value={dealer.dealerLocation} onChange={(e) => { updateDealer('dealerLocation', e.target.value) }} placeholder="location name" />

                </div>
                <div className="field">
                    <label htmlFor="refdate">Zonal Manager <span style={{ color: 'red' }}>*</span></label>
                    <Dropdown value={dealer.zonalManager} optionLabel='name' optionValue='id' onChange={(e) => { updateDealer('zonalManager', e.target.value) }} placeholder="Zonal Office" options={data.filter(i => i.role === 'clientuser').map(i => ({ name: i.empname, id: i.id }))} />
                    {submitted && dealer.zonalManager === null && <small className="p-invalid" style={{ color: 'red' }}>Zonal Manager is mandatory</small>}

                </div>
                <div className="field">
                    <label htmlFor="refdate">Zonal office <span style={{ color: 'red' }}>*</span></label>
                    <Dropdown value={dealer.zonalOffice} optionLabel='name' onChange={(e) => { updateDealer('zonalOffice', e.target.value) }} placeholder="Zonal Office" options={zonalOfficeList} />
                    {submitted && dealer.zonalOffice === null && <small className="p-invalid" style={{ color: 'red' }}>Zonal office is mandatory</small>}

                </div>

                <label className='fw-6 fs-18 clr-navy mb-2'>Dealer Principal Detail</label>
                <div className="field">
                    <label htmlFor="refdate">Dealer Principal Name</label>
                    <InputText value={dealer.principalName} onChange={(e) => { updateDealer('principalName', e.target.value) }} placeholder="Principal Name" />

                </div>
                <div className="field">
                    <label htmlFor="refdate">Dealer Principal Contact No</label>
                    <InputNumber useGrouping={false} value={dealer.principalContact} onChange={(e) => { updateDealer('principalContact', e.value) }} placeholder="Principal Contact" />

                </div>
                <label className='fw-6 fs-18 clr-navy mb-2'>Sales Manager Detail</label>
                <div className="field">
                    <label htmlFor="refdate">Sales Manager Name</label>
                    <InputText value={dealer.salesmName} onChange={(e) => { updateDealer('salesmName', e.target.value) }} placeholder="Sales Manager Name" />

                </div>
                <div className="field">
                    <label htmlFor="refdate">Sales Manager Contact No</label>
                    <InputNumber useGrouping={false} value={dealer.salesmContact} onChange={(e) => { updateDealer('salesmContact', e.value) }} placeholder="Sales Manager Contact" />

                </div>
                <label className='fw-6 fs-18 clr-navy mb-2'>Worker Manager Detail</label>
                <div className="field">
                    <label htmlFor="refdate">Worker Manager Name</label>
                    <InputText value={dealer.workersmName} onChange={(e) => { updateDealer('workersmName', e.target.value) }} placeholder="Worker Manager Name" />

                </div>
                <div className="field">
                    <label htmlFor="refdate">Worker Manager Contact No</label>
                    <InputNumber useGrouping={false} value={dealer.workersmContact} onChange={(e) => { updateDealer('workersmContact', e.value) }} placeholder="Worker Manager Contact" />

                </div>
                <label className='fw-6 fs-18 clr-navy mb-2'>Dealership Ownership</label>
                <div className="field">
                    <label htmlFor="refdate">Showroom <span style={{ color: 'red' }}>*</span></label>
                    <Dropdown value={dealer.showroomType} optionLabel='name' onChange={(e) => { updateDealer('showroomType', e.target.value) }} placeholder="Show Room Type" options={propertyTypeList} />
                    {submitted && dealer.showroomType === null && <small className="p-invalid" style={{ color: 'red' }}>Show Room Type is mandatory</small>}

                </div>

                <div className="field">
                    <label htmlFor="refdate">Service Centre <span style={{ color: 'red' }}>*</span></label>
                    <Dropdown value={dealer.servicecenterType} optionLabel='name' onChange={(e) => { updateDealer('servicecenterType', e.target.value) }} placeholder="Service Center Type" options={propertyTypeList} />
                    {submitted && dealer.servicecenterType === null && <small className="p-invalid" style={{ color: 'red' }}>Service Centre Type is mandatory</small>}

                </div>



            </Dialog>

        </div >
    )
}

export default AddClientDealerTVS



